import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import { Layout } from './components/nav/Layout';
import Home from './components/Home';
import AboutUs from './components/about-us';
import ContactUs from './components/contact-us.js';
//import { Industries_Agri } from './components/industries-agri.js';
//import { Industries_Bus } from './components/industries-bus.js';
//import { Industries_Marine } from './components/industries-marine.js';
//import { Industries_Military } from './components/industries-military.js';
//import { Industries_Mining } from './components/industries-mining.js';
//import { Industries_Rail } from './components/industries-rail.js';
import InnovationSupply from './components/innovation-supply.js';
import MediaLatestNews from './components/media-latest-news.js';
import QMS from './components/qms.js';
import QualityReport from './components/quality-reports.js';
import Technology from './components/technology.js';
import TermsAndConditions from './components/terms-and-conditions.js';
import PrivacyPolicy from './components/PrivacyPolicy.js';
import { InnovationManufacturing } from './components/innovation-manufacturing.js';

import { Login } from './components/Login';
import { Banners } from './components/admin/Banners';
import { AdminHome } from './components/admin/AdminHome';
import { UserData } from './components/admin/UserData';
import { ContactMessages } from './components/admin/ContactMessages';
import { ContactReasons } from './components/admin/ContactReasons';
import { NewsArticles } from './components/admin/NewsArticles';
import { PageContents } from './components/admin/PageContents';
import MediaArticle from './components/MediaArticle';
import QmsAdmin from './components/admin/Qms';
import { Innovations } from './components/admin/Innovations';
import { TechnologySlides } from './components/admin/TechnologySlides';
import { Industries as AdminIndustries } from './components/admin/Industries.js';
import { Industries } from './components/industries';
import { QualityReports } from './components/admin/QualityReport';


export default class App extends Component {
  static displayName = App.name;

  render() {
    return (
      // <div className="container-fluid">
      <Layout>
        <Route exact path='/' component={Home} />
        <Route exact path='/aboutus' component={AboutUs} />
        <Route exact path='/contactus' component={ContactUs} />
        <Route exact path='/mediaarticle/:slug' component={MediaArticle} />
        {/*<Route exact path='/industries/agri' component={Industries_Agri} />*/}
        {/*<Route exact path='/industries/bus' component={Industries_Bus} />*/}
        {/*<Route exact path='/industries/marine' component={Industries_Marine} />*/}
        {/*<Route exact path='/industries/military' component={Industries_Military} />*/}
        {/*<Route exact path='/industries/mining' component={Industries_Mining} />*/}
        {/*<Route exact path='/industries/rail' component={Industries_Rail} />*/}
        <Route exact path='/industries/*' component={Industries} />
        {/* 
        <Route exact path='/industries/agri' component={Industries} />
        <Route exact path='/industries/bus' component={Industries} />
        <Route exact path='/industries/marine' component={Industries} />
        <Route exact path='/industries/military' component={Industries} />
        <Route exact path='/industries/mining' component={Industries} />
        <Route exact path='/industries/rail' component={Industries} /> */}

        <Route exact path='/innovation/supply' component={InnovationSupply} />
        <Route exact path='/media/latestNews' component={MediaLatestNews} />
        <Route exact path='/qms' component={QMS} />
        <Route exact path='/qualityreports' component={QualityReport} />
        <Route exact path='/technology' component={Technology} />
        <Route exact path='/termsandconditions' component={TermsAndConditions} />
        <Route exact path='/privacypolicy' component={PrivacyPolicy} />
        <Route exact path='/innovation/manufacturing' component={InnovationManufacturing} />

        <Route exact path='/login' component={Login} />
        <Route exact path='/admin' component={AdminHome} />
        <Route exact path='/admin/banners' component={Banners} />
        <Route exact path='/admin/users' component={UserData} />
        <Route exact path='/admin/contactmessages' component={ContactMessages} />
        <Route exact path='/admin/contactreasons' component={ContactReasons} />
        <Route exact path='/admin/articles' component={NewsArticles} />
        <Route exact path='/admin/page-contents' component={PageContents} />
        <Route exact path='/admin/qms' component={QmsAdmin} />
        <Route exact path='/admin/innovations' component={Innovations} />
        <Route exact path='/admin/industries' component={AdminIndustries} />
        <Route exact path='/admin/technologies' component={TechnologySlides} />
        <Route exact path='/admin/quality-reports' component={QualityReports} />
      </Layout>
      // </div>
    );
  }
}
