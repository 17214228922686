import { Card, CardTitle, CardBody, CardSubtitle } from "reactstrap";
import { useHistory } from "react-router-dom";
import { getCurrentDomain } from "../helpers/lookup";

const Article = ({ articleID, articleSlug, title, subtitle, thumbnail }) => {
  const history = useHistory();

  const handleClick = (id) => {
    history.push({
      pathname: "/mediaArticle/" + articleSlug,
    });
  };

  return (
    <Card
      className="articleCard"
      // style={{ width: "27rem", height: "30rem" }}
      border="light"
    >
      <CardBody className="cardBody">
        <CardTitle className="cardTitle">{title}</CardTitle>
        <CardSubtitle className="cardSubtitle">{subtitle}</CardSubtitle>
        <a href={"/mediaArticle/" + articleSlug} className="cardBtn my-3" >
          Read More
        </a>
        <img src={thumbnail ? (getCurrentDomain() + thumbnail) : ("https://via.placeholder.com/300x150?text=" + title.replace(" ", "+"))} className="img-fluid" style={{ height: "300px" }} alt={title} />
      </CardBody>
      {/* <CardImg
        className="cardImg"
        variant="bottom"
        src=""
      /> */}
    </Card>
  );
};

export default Article;
