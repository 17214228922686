import React, { Component } from "react";

import { Button, Modal, ModalFooter, ModalHeader, ModalBody, Form, FormGroup, Label, Input, Row, Col, } from "reactstrap";
import { confirm } from "react-confirm-box";

import { getAuthToken, getLoginToken } from "../../helpers/authentication";
import "../../helpers/string-prototypes";
import { toastNotification } from "../../helpers/toastHelpers";

export class UserData extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editData: [],
      loading: true,
      editModal: false,
      showSuccess: false,
      showError: false,
      showDelete: false,
      Id: 0,
      UserId: "",
      UserType: 0,
      Salutation: 0,
      FirstName: "",
      LastName: "",
      EmailAddress: "",
      EmailConfirmed: false,
      CurrentPassword: "",
      LastPassword: "",
      Telephone: "",
      Mobile: "",
      Status: 0,
      DateAdded: new Date(),
      DateModified: new Date(),
      AddedBy: 0,
      DateVerified: new Date(),
      LastLogin: new Date(),
      FailedLogins: 0,
      LockedOut: false,
      LockOutDate: new Date(),
      ResetPassword: false,
      LastIP: "",
      LoginCookie: "",
    };
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState({
      editModal: !this.state.editModal,
    });
  }

  componentDidMount() {
    document.title = "User Administration";
    this.checkLoginStatus();
    this.loadData();
  }

  async checkLoginStatus() {
    const loginToken = getLoginToken();
    const bearer = "Bearer " + getAuthToken();

    try {
      const response = await fetch("/api/userlogin/CheckLoginStatus/" + loginToken, {
        method: "GET",
        headers: {
          Authorization: bearer,
          "Content-Type": "application/json"
        }
      });

      if (!response.ok)
        window.location.href = "/login";
    } catch (e) {
      console.error(e);
    }
  }

  editItem = (event) => {
    event.stopPropagation();
    const id = event.currentTarget.getAttribute("data-recordid");

    if (id > 0) {
      const data = this.state.editData.find((item) => {
        return item.Id === parseInt(id);
      });
      this.setState({
        Id: data.Id,
        UserId: data.UserId,
        UserType: data.UserType,
        Salutation: data.Salutation,
        FirstName: data.FirstName,
        LastName: data.LastName,
        EmailAddress: data.EmailAddress,
        EmailConfirmed: data.EmailConfirmed,
        CurrentPassword: data.CurrentPassword,
        LastPassword: data.LastPassword,
        Telephone: data.Telephone,
        Mobile: data.Mobile,
        Status: data.Status,
        DateAdded: data.DateAdded,
        DateModified: data.DateModified,
        AddedBy: data.AddedBy,
        DateVerified: data.DateVerified,
        LastLogin: data.LastLogin,
        FailedLogins: data.FailedLogins,
        LockedOut: data.LockedOut,
        LockOutDate: data.LockOutDate,
        ResetPassword: data.ResetPassword,
        LastIP: data.LastIP,
        LoginCookie: data.LoginCookie,
      });
    } else {
      //clear state fields
      this.setState({
        Id: 0,
        UserId: "",
        UserType: 0,
        Salutation: 0,
        FirstName: "",
        LastName: "",
        EmailAddress: "",
        EmailConfirmed: false,
        CurrentPassword: "",
        LastPassword: "",
        Telephone: "",
        Mobile: "",
        Status: 0,
        DateAdded: new Date(),
        DateModified: new Date(),
        AddedBy: 0,
        DateVerified: new Date(),
        LastLogin: new Date(),
        FailedLogins: 0,
        LockedOut: false,
        LockOutDate: new Date(),
        ResetPassword: false,
        LastIP: "",
        LoginCookie: "",
      });
    }
    this.setState({
      editId: id,
      editModal: true,
      showError: false,
      showSuccess: false,
    });
  };

  saveItem = async (event) => {
    event.stopPropagation();
    const data = this.state.editData.find((item) => {
      return item.Id === parseInt(this.state.editId);
    });
    if (this.state.editId > 0) {
      //do not overwrite the following fie when updating
      this.state.AddedBy = data.AddedBy;
      this.state.DateAdded = data.DateAdded;
      this.state.Id = data.Id;
      this.state.Status = data.Status;
    } else {
      this.state.Status = 1;
    }
    this.saveData(this.state.editId);
    this.setState({ editModal: false });
  };

  deleteItem = async (event) => {
    event.stopPropagation();
    this.setState({ showError: false, showSuccess: false });
    const id = event.currentTarget.getAttribute("data-recordid");
    const result = await confirm("Are you sure you want to delete this item?");
    if (result) {
      this.deleteData(id);
    }
  };

  static renderDataTable(data, editFunction, deleteFunction) {
    return (
      <table className="table table-striped" aria-labelledby="tabelLabel">
        <thead>
          <tr>
            <th>Name</th>
            <th>Last Login</th>
            <th className="text-end">Actions</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item) => (
            <tr key={item.Id}>
              <td>{item.FirstName} {item.LastName}</td>
              <td>{item.LastLogin}</td>
              <td className="text-end">
                {console.log(item.LockedOut)}
                {item.LockedOut && <i className="fas fa-lock text-danger me-2"></i>}
                <div className="btn-group">
                  <Button color="dark" outline size="sm" data-toggle="tooltip" data-placement="auto" data-recordid={item.Id} title="Edit" onClick={editFunction}>
                    <i className="far fa-edit"></i>
                  </Button>
                  <Button color="dark" outline size="sm" data-toggle="tooltip" data-placement="auto" data-recordid={item.Id} title="Delete" onClick={deleteFunction}>
                    <i className="far fa-trash-alt text-danger"></i>
                  </Button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }

  render() {
    let contents = this.state.loading ? (
      <p className="text-center">
        <i className="fas fa-spinner fa-spin me-2"></i>Loading...
      </p>
    ) : (
      UserData.renderDataTable(
        this.state.editData,
        this.editItem,
        this.deleteItem
      )
    );

    return (
      <>
        <div className="container">
          <Row>
            <Col xs={8}>
              <h1>User Data Administration</h1>
            </Col>
            <Col xs={4} className="text-end align-self-center">
              <Button color="primary" size="sm" data-recordid="0" onClick={this.editItem} >
                Add New <i className="fas fa-plus-circle ms-2"></i>
              </Button>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>{contents}</Col>
          </Row>
        </div>
        <Modal isOpen={this.state.editModal} toggle={this.toggle} className={this.props.className} scrollable size="lg" backdrop="static">
          <ModalHeader toggle={this.toggle} close={
            <button className="close" onClick={this.toggle}>
              <i className="fas fa-times"></i>
            </button>
          } >
            Edit User Data
          </ModalHeader>
          <ModalBody>
            <Form>
              {/*<FormGroup className="mb-3">*/}
              {/*  <Label for="tbUserId">User</Label>*/}
              {/*  <Input id="tbUserId" name="tbUserId" className="form-control" placeholder="User Id" type="text" maxLength="40" required value={this.state.UserId} onChange={(e) => this.setState({ UserId: e.target.value })} />*/}
              {/*</FormGroup>*/}

              {/*<FormGroup className="mb-3">*/}
              {/*  <Label for="tbUserType">User Type</Label>*/}
              {/*  <Input id="tbUserType" name="tbUserType" className="form-control text-end" placeholder="User Type" type="number" value={this.state.UserType} onChange={(e) => this.setState({ UserType: e.target.value })}                />*/}
              {/*</FormGroup>*/}

              <FormGroup className="mb-3 d-none">
                <Label for="tbSalutation">Salutation</Label>
                <Input id="tbSalutation" name="tbSalutation" className="form-control text-end" placeholder="Salutation" type="number" required value={this.state.Salutation} onChange={(e) => this.setState({ Salutation: e.target.value })} />
              </FormGroup>

              <div className="row">
                <FormGroup className="mb-3 col-md-6">
                  <Label for="tbFirstName">First Name</Label>
                  <Input id="tbFirstName" name="tbFirstName" className="form-control" placeholder="First Name" type="text" maxLength="250" value={this.state.FirstName} onChange={(e) => this.setState({ FirstName: e.target.value })} />
                </FormGroup>

                <FormGroup className="mb-3 col-md-6">
                  <Label for="tbLastName">Last Name</Label>
                  <Input id="tbLastName" name="tbLastName" className="form-control" placeholder="Last Name" type="text" maxLength="250" value={this.state.LastName} onChange={(e) => this.setState({ LastName: e.target.value })} />
                </FormGroup>

                <FormGroup className="mb-3 col-md-8">
                  <Label for="tbEmailAddress">Email Address</Label>
                  <Input id="tbEmailAddress" name="tbEmailAddress" className="form-control" placeholder="Email Address" type="text" maxLength="350" required value={this.state.EmailAddress} onChange={(e) => this.setState({ EmailAddress: e.target.value })} />
                </FormGroup>

                <FormGroup className="mb-3 col-md-4">
                  <Label for="tbCurrentPassword">Password</Label>
                  <Input id="tbCurrentPassword" name="tbCurrentPassword" className="form-control" placeholder="Current Password" type="password" maxLength="350" value={this.state.CurrentPassword} onChange={(e) => this.setState({ CurrentPassword: e.target.value })} />
                </FormGroup>




                <FormGroup className="mb-3 col-md-3">
                  <Input id="cbLockedOut" name="cbLockedOut" type="checkbox" checked={this.state.LockedOut} onChange={(e) => this.setState({ LockedOut: e.target.checked })} />{" "}
                  &nbsp; <Label for="cbLockedOut">Locked Out</Label>
                </FormGroup>



                <FormGroup className="mb-3 col-md-3">
                  <Input id="cbResetPassword" name="cbResetPassword" type="checkbox" checked={this.state.ResetPassword} onChange={(e) => this.setState({ ResetPassword: e.target.checked })} />{" "}
                  &nbsp; <Label for="cbResetPassword">Reset Password</Label>
                </FormGroup>
              </div>

            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="dark" size="sm" onClick={this.toggle}>Cancel <i className="far fa-times-circle ms-2"></i></Button>
            <Button color="success" size="sm" onClick={this.saveItem}>Save <i className="far fa-check-circle ms-2"></i></Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }

  async loadData() {
    var bearer = "Bearer " + getAuthToken();
    try {
      const response = await fetch("api/userdata", {
        method: "GET",
        withCredentials: true,
        credentials: "include",
        headers: {
          Authorization: bearer,
          "Content-Type": "application/json",
        },
      });
      if (response.ok) {
        const data = await response.json();
        this.setState({ editData: data, loading: false });
      } else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401) this.props.history.push("/login");
      }
    } catch (e) {
      console.error(e);
    }
  }

  async saveData(dataId) {
    this.setState({ loading: true, showError: false, showSuccess: false });

    var bearer = "Bearer " + getAuthToken();
    var data = {
      Id: this.state.Id,
      UserId: this.state.UserId,
      UserType: this.state.UserType,
      Salutation: this.state.Salutation,
      FirstName: this.state.FirstName,
      LastName: this.state.LastName,
      EmailAddress: this.state.EmailAddress,
      EmailConfirmed: this.state.EmailConfirmed,
      CurrentPassword: this.state.CurrentPassword,
      LastPassword: this.state.LastPassword,
      Telephone: this.state.Telephone,
      Mobile: this.state.Mobile,
      Status: this.state.Status,
      DateAdded: this.state.DateAdded,
      DateModified: this.state.DateModified,
      AddedBy: this.state.AddedBy,
      DateVerified: this.state.DateVerified,
      LastLogin: this.state.LastLogin,
      FailedLogins: this.state.FailedLogins,
      LockedOut: this.state.LockedOut,
      LockOutDate: this.state.LockOutDate,
      ResetPassword: this.state.ResetPassword,
      LastIP: this.state.LastIP,
      LoginCookie: this.state.LoginCookie,
    };

    try {
      const response = await fetch("api/userdata", {
        method: dataId === 0 ? "POST" : "PUT",
        withCredentials: true,
        credentials: "include",
        headers: {
          Authorization: bearer,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      await response.json();
      this.loadData();
      this.setState({ showSuccess: true });
      toastNotification(
        "User Data",
        "The user data was successfully saved!",
        true
      );
    } catch (e) {
      console.error(e);
      this.setState({ loading: false, showError: true });
      toastNotification(
        "User Data",
        "There was an error saving the user data!",
        false
      );
    }
  }

  async deleteData(dataId) {
    this.setState({ loading: true });

    var bearer = "Bearer " + getAuthToken();
    try {
      const response = await fetch("api/userdata/" + dataId, {
        method: "DELETE",
        withCredentials: true,
        credentials: "include",
        headers: {
          Authorization: bearer,
          "Content-Type": "application/json",
        },
      });
      await response.json();
      this.loadData();
      this.setState({ showDelete: true });
      toastNotification(
        "User Data",
        "The user data was successfully deleted!",
        true
      );
    } catch (e) {
      console.error(e);
      this.setState({ loading: false, showError: true });
      toastNotification(
        "User Data",
        "There was an error when deleting the user data!",
        false
      );
    }
  }
}
