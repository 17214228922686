/* eslint-disable react/jsx-no-target-blank */
import React, { Component } from "react";
import { getAuthToken } from "../../helpers/authentication";

export class Footer extends Component {
  static copyrightDate = "";

  constructor(props) {
    super(props);
    this.copyrightDate = new Date().getFullYear();

    this.state = {
      Industries: []
    }
  }

  componentDidMount() {
    this.loadIndustries();
  }

  async loadIndustries() {

    var bearer = 'Bearer ' + getAuthToken();
    try {
      const response = await fetch('/api/industries', {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        },
      });

      if (response.ok) {
        const body = await response.json()
        this.setState({ Industries: body })
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          this.props.history.push("/login");
      }
    } catch (e) {
      console.error(e);
    }
  }

  renderIndustries(industries) {

    return (
      industries.map((item, idx) =>
        <p key={idx}>
          <a href={`/industries/${item.Title.replaceAll(" ", "")}`} >{item.Title}</a>
        </p>
      )
    )
  }

  render() {
    return (
      <>
        <footer>
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <h2 className="mt-0">Get in Touch!</h2>
              </div>
              <div className="col-md-6 text-md-end text-center">
                <a href="/contactus" className="btn-contact me-2">
                  Email Us
                </a>
                <a href="tel:+27357511102" className="btn-contact">
                  Call Us
                </a>
              </div>

              <div className="col-12">
                <hr />
              </div>
            </div>
            <div className="row">
              <div className="col-md-4 col-lg-2">
                <h6>Innovation</h6>
                <p>
                  <a href="/innovation/manufacturing">
                    Manufacturing & Engineering Capabilities
                  </a>
                </p>
                <p>
                  <a href="/innovation/supply">International Supply</a>
                </p>
              </div>

              <div className="col-md-4 col-lg-2">
                <h6>Quality</h6>
                <p>
                  <a href="/qualityreports">Quality & Testing</a>
                </p>
              </div>

              <div className="col-md-4 col-lg-2">
                <h6>Technology</h6>
                <p>
                  <a href="/technology">Manufacturing Technology</a>
                </p>
              </div>

              <div className="col-md-4 col-lg-2">
                <h6>Industries</h6>
                {/* <p>
                  <Link to="/industries/mining">Mining</Link>
                </p>
                <p>
                  <Link to="/industries/agri">Agri &amp; Earthmoving</Link>
                </p>
                <p>
                  <Link to="/industries/rail">Rail</Link>
                </p>
                <p>
                  <Link to="/industries/military">Military</Link>
                </p>
                <p>
                  <Link to="/industries/bus">Bus & Truck</Link>
                </p>
                <p>
                  <Link to="/industries/marine">Marine</Link>
                </p> */}
                {this.renderIndustries(this.state.Industries)}
              </div>

              <div className="col-md-4 col-lg-2">
                <h6>Media</h6>
                <p>
                  <a href="/media/latestnews">Latest News</a>
                </p>
              </div>

              <div className="col-md-4 col-lg-2">
                <h6>About Us</h6>
                <p>
                  <a href="/aboutus">Holding Company</a>
                </p>
                <p>
                  <a href="/aboutus">Company Objective</a>
                </p>
                <p>
                  <a href="/aboutus">Our Products</a>
                </p>
                <p>
                  <a href="/qms">QMS</a>
                </p>
              </div>
            </div>

            <div className="row">
              <div className="col-12 copyright">
                Copyright&copy; {this.copyrightDate} HarnessPro.co.za. All Rights Reserved. Designed by Trysome's Teal Design & Advertising
                <span className="mx-2">|</span>
                <a href="/termsandconditions">Terms &amp; Conditions</a>
                <span className="mx-2">|</span>
                <a href="/privacypolicy">Privacy Policy</a>
              </div>
            </div>
          </div>
        </footer>
      </>
    );
  }
}
