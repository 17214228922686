import React, { Component } from "react";
import { getAuthToken, getLoginToken } from "../../helpers/authentication";
import { Spinner } from "reactstrap";

export class AdminHome extends Component {
  rteObject;

  constructor(props) {
    super(props);

    this.state = {
      loading: true
    }
  }


  componentDidMount() {
    document.title = "HarnessPro Admin";
    this.checkLoginStatus();
  }

  async checkLoginStatus() {
    const loginToken = getLoginToken();
    const bearer = "Bearer " + getAuthToken();

    try {
      const response = await fetch("/api/userlogin/CheckLoginStatus/" + loginToken, {
        method: "GET",
        headers: {
          Authorization: bearer,
          "Content-Type": "application/json"
        }
      });

      if (!response.ok)
        window.location.href = "/login";
      else
        this.setState({ loading: false });
    } catch (e) {
      console.error(e);
    }
  }

  renderDashboard() {
    return (
      <>
        <h3>Quick link buttons</h3>

        <div className="row my-4 text-center">
          <div className="col-md-4 mb-3">
            <a href="/admin/banners" className="btn-purple btn-lg fs-3">
              Home Banners
            </a>
          </div>
          <div className="col-md-4  mb-3">
            <a href="/admin/page-contents" className="w-100 btn-purple  btn-lg fs-3">
              Page Content
            </a>
          </div>
          <div className="col-md-4 mb-3">
            <a href="/admin/qms" className="btn-purple btn-lg fs-3">
              QMS
            </a>
          </div>
          <div className="col-md-4 mb-3">
            <a href="/admin/innovations" className="btn-purple btn-lg fs-3">
              Innovations
            </a>
          </div>
          <div className="col-md-4 mb-3">
            <a href="/admin/industries" className="btn-purple btn-lg fs-3">
              Industries
            </a>
          </div>
          <div className="col-md-4 mb-3">
            <a href="/admin/technologies" className="btn-purple btn-lg fs-3">
              Technologies
            </a>
          </div>
          <div className="col-md-4 mb-3 ">
            <a href="/admin/quality-reports" className="btn-purple btn-lg fs-3">
              Quality Reports
            </a>
          </div>
          <div className="col-md-4 mb-3 ">
            <a href="/admin/articles" className="btn-purple btn-lg fs-3">
              News Articles
            </a>
          </div>
          <div className="col-md-4 mb-3 ">
            <a href="/admin/contactmessages" className="btn-purple btn-lg fs-3">
              Contact Messages
            </a>
          </div>
          <div className="col-md-4 mb-3 ">
            <a href="/admin/contactreasons" className="btn-purple btn-lg fs-3">
              Contact Reasons
            </a>
          </div>
          <div className="col-md-4 mb-3 ">
            <a href="/admin/users" className="btn-purple btn-lg fs-3">
              Users
            </a>
          </div>
        </div>
      </>
    )
  }

  render() {

    const dashboard = this.state.loading ? <><Spinner color="dark" size={"sm"} />Loading</> : this.renderDashboard();
    return (
      <div className="container">
        {dashboard}
      </div>
    );
  }

}
