import React, { Component } from 'react';
import { Button, Modal, ModalFooter, ModalHeader, ModalBody, Label, Input, Row, Col } from 'reactstrap';
import { ColumnDirective, ColumnsDirective, CommandColumn, GridComponent, Sort, Search, ExcelExport, Edit, Toolbar, ToolbarItems } from '@syncfusion/ej2-react-grids';
import { DatePickerComponent, TimePickerComponent } from '@syncfusion/ej2-react-calendars';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import { ToastUtility } from '@syncfusion/ej2-react-notifications';
import { TextBoxComponent, NumericTextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { HtmlEditor, Image, Inject, Count, Link, Toolbar as rteToolbar, QuickToolbar, RichTextEditorComponent } from '@syncfusion/ej2-react-richtexteditor';
import { confirm } from "react-confirm-box";
import { getAuthToken, getLoginToken } from '../../helpers/authentication';
import { fieldRequired } from '../../helpers/validation';
import { getCurrentDomain } from '../../helpers/lookup';
import ImageEditor from './ImageEditor';
import { toastNotification } from '../../helpers/toastHelpers';

export class NewsArticles extends Component {

  constructor(props) {
    super(props);
    const commandTemplate = [
      { type: 'Edit', buttonOption: { cssClass: 'e-flat', iconCss: 'e-edit e-icons' } },
      { type: 'Delete', buttonOption: { cssClass: 'e-flat', iconCss: 'e-delete e-icons' } }
    ];

    this.fullToolbarSettings = {
      items: ['Bold', 'Italic', 'Underline', 'StrikeThrough',
        'FontName', 'FontSize', 'FontColor', 'BackgroundColor',
        'LowerCase', 'UpperCase', '|', 'Formats', 'Alignments', 'OrderedList', 'UnorderedList',
        'Outdent', 'Indent', '|', 'CreateLink', 'Image', '|', 'ClearFormat',
        'SourceCode', 'FullScreen', '|', 'Undo', 'Redo'
      ],
      type: 'Expand'
    };

    this.simpleToolbarSettings = {
      items: ['Bold', 'Italic', 'Underline', '|', 'ClearFormat', 'Undo', 'Redo', '|', 'SourceCode'],
      type: 'Expand'
    };

    this.imageSettings = {
      saveUrl: "api/newsarticles/SaveFile",
      path: getCurrentDomain() + "DynamicData/News/",
      classNames: ["img-fluid"]
    }

    this.state = {
      editData: [], ddList: [], loading: true, editModal: false, gridCommands: commandTemplate, gridToolbar: ['Search'],
      Id: 0,
      Slug: '',
      Title: '',
      Author: '',
      ArticleDate: new Date(),
      Thumbnail: '',
      Snippet: '',
      DateAdded: new Date(),
      AddedBy: 0,
      DateModified: new Date(),
      ModifiedBy: 0,
      Status: 0,
      Copy: '',
      ExistingThumbnail: '',
      FileData: ''

    };
    this.toggle = this.toggle.bind(this);
    this.renderDataTable = this.renderDataTable.bind(this);
    this.onItemChange = this.onItemChange.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
  }

  toggle() {
    this.setState({
      editModal: !this.state.editModal
    });
  }

  componentDidMount() {
    document.title = "News Articles Administration";
    this.checkLoginStatus();
    this.loadData(0);
  }

  async checkLoginStatus() {
    const loginToken = getLoginToken();
    const bearer = "Bearer " + getAuthToken();

    try {
      const response = await fetch("/api/userlogin/CheckLoginStatus/" + loginToken, {
        method: "GET",
        headers: {
          Authorization: bearer,
          "Content-Type": "application/json"
        }
      });

      if (!response.ok)
        window.location.href = "/login";
    } catch (e) {
      console.error(e);
    }
  }

  editItem = (id) => {
    if (id > 0) {
      const data = this.state.editData.find((item) => { return item.Id === id });
      this.setState({ Id: data.Id, Slug: data.Slug, Title: data.Title, Author: data.Author, ArticleDate: data.ArticleDate, Thumbnail: data.Thumbnail, ExistingThumbnail: data.Thumbnail, Snippet: data.Snippet, Copy: data.Copy.replace(/\/DynamicData/g, getCurrentDomain() + "DynamicData"), });
    }
    else {
      //clear state fields
      this.setState({
        Id: 0,
        Slug: '',
        Title: '',
        Author: '',
        ArticleDate: new Date(),
        Thumbnail: '',
        Snippet: '',
        Copy: '',
        ExistingThumbnail: '',
        FileData: ''
      });
    }
    this.setState({ editId: id, editModal: true });
  };

  saveItem = async (e) => {
    e.stopPropagation()
    var valid = true;
    valid &= fieldRequired(this.state.Title, 'tbTitleError', '* required');
    valid &= fieldRequired(this.state.Author, 'tbAuthorError', '* required');
    valid &= fieldRequired(this.state.ArticleDate, 'tbArticleDateError', '* required');
    valid &= fieldRequired(this.state.Snippet, 'tbSnippetError', '* required');
    valid &= fieldRequired(this.state.Copy, 'tbCopyError', '* required');

    if (valid) {
      // const data = this.state.editData.find((item) => { return item.Id === this.state.Id });
      // // if (this.state.editId > 0) { //do not overwrite the following fie when updating
      // //   this.state.AddedBy = data.AddedBy;
      // //   this.state.DateAdded = data.DateAdded;
      // //   this.state.Id = data.Id;
      // //   this.state.Status = data.Status;
      // // }
      // // else {
      // //   this.state.Status = 1;
      // // }
      this.saveData(this.state.editId);
      this.setState({ editModal: false });
    }
  }

  deleteItem = async (id) => {
    const confirmStyles = {
      classNames: {
        confirmButton: 'btn btn-sm btn-success',
        cancelButton: 'btn btn-sm btn-danger',
      }
    }

    window.scrollTo({
      top: 0,
      behavior: "instant"
    });

    const result = await confirm("Are you sure you want to delete this item?", confirmStyles);
    if (result) {
      this.deleteData(this.state.Id);
    }
  }

  // onGridCommand = (args) => {
  //   switch (args.commandColumn.type) {
  //     case 'Edit':
  //       this.editItem(args.rowData.Id);
  //       break;
  //     case 'Delete':
  //       this.deleteItem(args.rowData.Id);
  //       break;
  //   }
  // }

  onItemChange(e) {

    const Id = e.itemData.Id;
    const content = this.state.editData.find(c => c.Id === Id);

    let Slug = "";
    let Title = "";
    let Author = "";
    let ArticleDate = new Date();
    let Thumbnail = "";
    let Snippet = "";
    let Copy = "";

    if (content) {
      Slug = content.Slug;
      Title = content.Title;
      Author = content.Author;
      ArticleDate = content.ArticleDate;
      Thumbnail = content.Thumbnail;
      Snippet = content.Snippet;
      Copy = content.Copy.replace(/\/DynamicData/g, getCurrentDomain() + "DynamicData");
    }

    this.setState({
      Id, Slug, Title, Author, ArticleDate, Thumbnail, Snippet, Copy, FileData: ""
    }, () => ImageEditor.UpdateImage(Thumbnail));

  }


  renderDataTable() {
    return (
      // <GridComponent dataSource={data} ref={g => this.grid = g} commandClick={commandClick} allowSorting={true} toolbar={gridToolbar} >
      //   <ColumnsDirective>
      //     <ColumnDirective field='Title' width='300' headerText="Title" />
      //     <ColumnDirective field='ArticleDate' width='100' headerText="Article Date" type="datetime" format="yyyy-MM-dd" />
      //     <ColumnDirective headerText='Actions' width='100' commands={gridCommands} />
      //   </ColumnsDirective>
      //   <Inject services={[Sort, Edit, CommandColumn, Toolbar]} />
      // </GridComponent>
      <>
        <div className="row mt-3">
          <div className="col-12 mb-3">
            <label htmlFor="">Select Item</label>
            <DropDownListComponent dataSource={this.state.ddList} fields={{ value: "Id", text: "Title" }} floatLabelType='auto' value={this.state.Id} select={this.onItemChange} />
          </div>

          <div className="col-md-6 mb-3">
            <TextBoxComponent id='tbTitle' name='tbTitle' placeholder='Title' type='text' maxLength='300' floatLabelType='Auto' showClearButton={true} value={this.state.Title} onChange={e => this.setState({ Title: e.target.value })} /> <div id='tbTitleError' className='ErrorText' />
          </div>
          <div className="col-md-6 mb-3">
            <TextBoxComponent disabled placeholder='Slug' type='text' floatLabelType='Always' value={this.state.Slug} />
          </div>
          <div className='mb-3 col-md-9'>
            <TextBoxComponent id='tbAuthor' name='tbAuthor' placeholder='Author' type='text' maxLength='300' floatLabelType='Auto' showClearButton={true} value={this.state.Author} onChange={e => this.setState({ Author: e.target.value })} /> <div id='tbAuthorError' className='ErrorText' />
          </div>

          <div className='mb-3 col-md-3'>
            <DatePickerComponent id='tbArticleDate' name='tbArticleDate' placeholder='Article Date' floatLabelType='Auto' showClearButton={true} format='yyyy-MM-dd' value={this.state.ArticleDate} onChange={e => this.setState({ ArticleDate: e.target.value })} /> <div id='tbArticleDateError' className='ErrorText' />
          </div>
          <div className='col-12 mb-3'>
            <Label for='tbSnippet'>Snippet<span id='tbSnippetError' className='ErrorText' /></Label>
            <RichTextEditorComponent id='tbSnippet' name='tbSnippet' placeholder='Snippet' toolbarSettings={this.simpleToolbarSettings} showCharCount={true} value={this.state.Snippet} change={e => this.setState({ Snippet: e.value })} >
              <Inject services={[rteToolbar, HtmlEditor, QuickToolbar, Count]} />
            </RichTextEditorComponent>
          </div>

          <div className='col-12 mb-3'>
            <Label for='tbCopy'>Copy <span id='tbCopyError' className='ErrorText' /></Label>
            <RichTextEditorComponent id='tbCopy' name='tbCopy' height={"500px"} placeholder='Copy' insertImageSettings={this.imageSettings} imageUploadSuccess={this.onImageUploadSuccess} toolbarSettings={this.fullToolbarSettings} showCharCount={true} value={this.state.Copy} change={e => this.setState({ Copy: e.value })} >
              <Inject services={[rteToolbar, HtmlEditor, QuickToolbar, Image, Count]} />
            </RichTextEditorComponent>
          </div>

          <div className="col-md-6 mb-3">
            <ImageEditor LabelText={"Thumbnail"} ExistingImage={this.state.Thumbnail} Size={"500"} OnChangeMethod={this.handleFileChange} />
          </div>

        </div>
        <div className="text-end">
          <Button color='danger' size='sm' onClick={this.deleteItem} className='me-2' hidden={this.state.Id === 0}>Delete  <i className="far fa-times-circle ms-2"></i></Button>
          <Button color="success" size="sm" onClick={this.saveItem}>Save <i className="far fa-check-circle ms-2"></i></Button>
        </div>
      </>
    );
  }

  onImageUploadSuccess(args) {
    if (args.e.currentTarget.getResponseHeader('name') != null) {
      args.file.name = args.e.currentTarget.getResponseHeader('name');
      let filename = document.querySelectorAll(".e-file-name")[0];
      filename.innerHTML = args.file.name.replace(document.querySelectorAll(".e-file-type")[0].innerHTML, '');
      filename.title = args.file.name;
    }
  }

  // handleFileChange(e) {
  //   let file = e.target.files[0];
  //   let Thumbnail = this.state.Thumbnail;
  //   let FileData = this.state.FileData;
  //   if (file) {
  //     const reader = new FileReader();
  //     reader.onloadend = () => {
  //       Thumbnail = file.name;
  //       FileData = reader.result.split(",")[1];;
  //       this.setState({
  //         Thumbnail, FileData
  //       });
  //     };
  //     reader.readAsDataURL(file);
  //   }
  // }

  handleFileChange(Thumbnail, FileData) {
    this.setState({
      Thumbnail, FileData
    }, () => console.log(this.state));
  }


  render() {
    let contents = this.state.loading ? <p className='text-center'><i className='fas fa-spinner fa-spin me-2'></i>Loading...</p> : this.renderDataTable(this.state.editData, this.state.gridCommands, this.state.gridToolbar, this.onGridCommand);
    return (
      <>
        <div className="container">
          <Row>
            <Col xs={12}>
              <h1>News Articles Administration</h1>
            </Col>
            {/* <Col xs={6} className="text-end align-self-center">
              <Button color="primary" size="sm" data-recordid="0" onClick={this.editItem}>Add New <i className="fas fa-plus-circle ms-2"></i></Button>
            </Col> */}
          </Row>
          <Row>
            <Col xs={12}>
              {contents}
            </Col>
          </Row>
        </div>

        {/* <Modal isOpen={this.state.editModal} toggle={this.toggle} className={this.props.className} scrollable size="lg" backdrop="static">
          <ModalHeader toggle={this.toggle} close={<button className="close" onClick={this.toggle}><i className="fas fa-times"></i></button>}>Edit News Articles</ModalHeader>
          <ModalBody>
            <div className='mb-3'>
              <TextBoxComponent id='tbTitle' name='tbTitle' placeholder='Title' type='text' maxLength='300' floatLabelType='Auto' showClearButton={true} value={this.state.Title} onChange={e => this.setState({ Title: e.target.value })} /> <div id='tbTitleError' className='ErrorText' />
            </div>

            <div className="row">
              <div className='mb-3 col-md-8'>
                <TextBoxComponent id='tbAuthor' name='tbAuthor' placeholder='Author' type='text' maxLength='300' floatLabelType='Auto' showClearButton={true} value={this.state.Author} onChange={e => this.setState({ Author: e.target.value })} /> <div id='tbAuthorError' className='ErrorText' />
              </div>

              <div className='mb-3 col-md-4'>
                <DatePickerComponent id='tbArticleDate' name='tbArticleDate' placeholder='Article Date' floatLabelType='Auto' showClearButton={true} format='yyyy-MM-dd' value={this.state.ArticleDate} onChange={e => this.setState({ ArticleDate: e.target.value })} /> <div id='tbArticleDateError' className='ErrorText' />
              </div>
            </div>

            <div className='mb-3'>
              <Label for='tbSnippet'>Snippet</Label>
              <RichTextEditorComponent id='tbSnippet' name='tbSnippet' placeholder='Snippet' toolbarSettings={this.simpleToolbarSettings} showCharCount={true} value={this.state.Snippet} change={e => this.setState({ Snippet: e.value })} >
                <Inject services={[rteToolbar, HtmlEditor, QuickToolbar, Count]} />
              </RichTextEditorComponent><div id='tbSnippetError' className='ErrorText' />
            </div>

            <div className='mb-3'>
              <Label for='tbCopy'>Copy</Label>
              <RichTextEditorComponent id='tbCopy' name='tbCopy' placeholder='Copy' insertImageSettings={this.imageSettings} imageUploadSuccess={this.onImageUploadSuccess} toolbarSettings={this.fullToolbarSettings} showCharCount={true} value={this.state.Copy} change={e => this.setState({ Copy: e.value })} >
                <Inject services={[rteToolbar, HtmlEditor, QuickToolbar, Image, Count]} />
              </RichTextEditorComponent><div id='tbCopyError' className='ErrorText' />
            </div>

            <div className="mb-3">
              <ImageEditor LabelText={"Thumbnail"} ExistingImage={this.state.Thumbnail} Size={"350"} OnChangeMethod={this.handleFileChange} />
              {/* <Label>Thumbnail</Label>
              <input
                type='file'
                accept="image/*"
                id='tbInactiveImage'
                className='form-control form-control-sm'
                onChange={(e) => this.handleFileChange(e, "InactiveImage", "InactiveImageName")}
              />
              <div dangerouslySetInnerHTML={{ __html: `<span class='text-muted fw-bolder'>Existing:</span> ${this.state.ExistingThumbnail}` }} hidden={this.state.ExistingThumbnail === ""}></div>
           *s/s}
            </div>


          </ModalBody>
          <ModalFooter>
            <Button color="dark" size="sm" onClick={this.toggle}>Cancel <i className="far fa-times-circle ms-2"></i></Button>
            <Button color="success" size="sm" onClick={this.saveItem}>Save <i className="far fa-check-circle ms-2"></i></Button>
          </ModalFooter>
        </Modal> */}
      </>

    );
  }

  async loadData(Id) {

    var bearer = 'Bearer ' + getAuthToken();
    try {
      const response = await fetch('api/newsarticles', {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();

        let ddList = [{ Id: 0, Title: " - Add New Article -" }, ...data];
        let Slug = "";
        let Title = "";
        let Author = "";
        let ArticleDate = new Date();
        let Thumbnail = "";
        let Snippet = "";
        let Copy = "";

        const content = data.find(n => n.Id === Id);

        if (content) {
          Id = content.Id;
          Slug = content.Slug;
          Title = content.Title;
          Author = content.Author;
          ArticleDate = content.ArticleDate;
          Thumbnail = content.Thumbnail;
          Snippet = content.Snippet;
          Copy = content.Copy.replace(/\/DynamicData/g, getCurrentDomain() + "DynamicData");
        }
        this.setState({ editData: data, ddList, Id, Title, Slug, Author, ArticleDate, Thumbnail, Snippet, Copy, FileData: "", loading: false });
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          this.props.history.push("/login");
      }

    } catch (e) {
      console.error(e);
    }
  }

  async saveData(dataId) {

    this.setState({ loading: true, showError: false, showSuccess: false });

    var bearer = 'Bearer ' + getAuthToken();
    var data = { Id: this.state.Id, Slug: this.state.Slug, Title: this.state.Title, Author: this.state.Author, ArticleDate: this.state.ArticleDate, Snippet: this.state.Snippet, Copy: this.state.Copy, Thumbnail: this.state.Thumbnail, FileData: this.state.FileData }

    try {
      const response = await fetch('api/newsarticles', {
        method: 'POST',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data),
      });
      if (response.ok) {
        const Id = await response.json();
        this.loadData(Id);
        // ToastUtility.show({
        //   title: content:timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-success'
        // });
        toastNotification('News Articles', 'The news articles was successfully saved!', true);
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          this.props.history.push("/login");
      }

    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
      // ToastUtility.show({
      //   title: content:  timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-danger'
      // });
      toastNotification('News Articles', 'There was an error saving the news articles!', false);
    }
  }

  async deleteData(dataId) {
    this.setState({ loading: true });

    var bearer = 'Bearer ' + getAuthToken();
    try {
      const response = await fetch('api/newsarticles/' + dataId, {
        method: 'DELETE',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        await response.json();
        this.loadData(0);
        // ToastUtility.show({
        //   title: 'News Articles', content: 'The news articles was successfully deleted!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-success'
        // });
        toastNotification('News Articles', 'The news articles was successfully deleted!', true)
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          this.props.history.push("/login");
      }
    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
      // ToastUtility.show({
      //   title: 'News Articles', content: 'There was an error deleting the news articles!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-danger'
      // });

      toastNotification('News Articles', 'There was an error deleting the news articles!', false);
    }
  }
}

