// import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink, UncontrolledDropdown, Dropdown, DropdownItem, DropdownToggle, DropdownMenu } from 'reactstrap';

import { useEffect, useState } from "react";
import { getCurrentDomain } from "../helpers/lookup";

const InnovationSupply = () => {

  const [Content, setContent] = useState(null);
  const [Loading, setLoading] = useState(true);

  useEffect(() => {
    loadData();
  }, [Loading])

  // console.log()

  const loadData = async () => {
    try {
      const response = await fetch("api/pagecontents/GetContentByPage/innovation", {
        method: "GET",
        withCredentials: true,
        credentials: "include",
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (response.ok) {
        const ContentList = await response.json();

        setContent(ContentList);
        setLoading(false);
      } else {
        console.log(response.status + ": " + response.statusText);
        setLoading(false);
      }
    } catch (e) {
      console.error("Page Content:", e);
    }
  }
  const contents = Loading ? <p><i className='fas fa-spinner fa-spin me-2'></i>Loading...</p> : <div className="pb-5" dangerouslySetInnerHTML={{ __html: Content[0].Copy.replace("/DynamicData", getCurrentDomain()+ "DynamicData") }} ></div>;
  return (
    <div>
      <div className="container">
        <h1>International Supply</h1>
        {contents}
      </div>
    </div>
  );
};

export default InnovationSupply;
