/* eslint-disable react/jsx-no-target-blank */
import { useEffect, useState } from "react";
import { Container, Row, Col, Carousel, CarouselItem, CarouselIndicators, CarouselControl } from "reactstrap";
import { getCurrentDomain } from "../../helpers/lookup";

const HomeBanner = () => {

  const [currentIdx, setCurrentIdx] = useState(0);
  const [animating, setAnimating] = useState(false);
  const [Banners, setBanners] = useState([]);
  const [Loading, setLoading] = useState(true);

  useEffect(() => {

    loadData();
  }, [Loading]);

  const loadData = async () => {

    try {
      const response = await fetch("api/banners/GetBanners", {
        method: "GET",
        withCredentials: true,
        credentials: "include",
        headers: {
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        setBanners(data);
        setLoading(false);
      }
      else
        setLoading(false);
    } catch (e) {
      console.error(e);
      setLoading(false);
    }
  }

  const getStyles = (data) => {
    var imagePath = getCurrentDomain() + data.Filename.replace(/\\/g, "/");

    var style = {
      backgroundImage: `url("${imagePath}")`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundPosition: "center top",
      height: "100vh",
      // boxShadow: "inset rgba(255,255,255,0.8) 0px -500px 100px 0px"
    }
    return style;
  }

  const itemLength = Banners.length - 1

  const onNext = (e) => {
    if (animating) return;
    const nextIndex = currentIdx === itemLength ? 0 : currentIdx + 1;
    setCurrentIdx(nextIndex);
  }

  const onPrev = (e) => {
    if (animating) return;
    const prevIndex = currentIdx === 0 ? itemLength : currentIdx - 1;
    setCurrentIdx(prevIndex);
  }

  const onIndicatorClick = (idx) => {
    console.log(idx);
    if (animating) return;
    setCurrentIdx(idx);
  }


  const RenderBanners = () => {

    return (
      // <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel" data-bs->
      <>
        <Carousel id="carouselExampleIndicators" activeIndex={currentIdx} slide ride="carousel" next={onNext} previous={onPrev} interval={2500}>
          {
            Banners.map((item, idx) =>
              <CarouselItem key={item.Id} onExited={() => setAnimating(false)} onExiting={() => setAnimating(true)}>
                <Container>
                  <Row>
                    <Col md={8}>
                      <h1 className="banner-h1">
                        {item.Title}
                      </h1>
                    </Col>
                  </Row>
                </Container>
                <Row>
                  <Col className="position-relative" >
                    <div style={getStyles(item)}>
                      <div className="container h-100 pb-5">
                        <div className="row h-100">
                          <div className="col-md-4 align-self-end mb-5 pb-md-5">
                            <a href={(item.ActionData ? item.ActionData : "/aboutus")} className="btn-submit mb-5" target={(item.ActionData ? "_blank" : "_self")} rel="norefferer">
                              Read More
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>


              </CarouselItem>
            )
          }

          <div className="carousel-indicators" style={{ bottom: "50px" }}>
            {Banners.map((item, idx) =>
              <button type="button" key={item.Id} onClick={() => onIndicatorClick(idx)} className={"btn-carousel-indicator btn-sm mx-2 p-2" + (idx === currentIdx ? " active" : "")}></button>
            )}
          </div>

          {/* <CarouselIndicators activeIndex={currentIdx} onClickHandler={onIndicatorClick} items={Banners} style={{ width: "100%", bottom: "50px" }}> */}

          {/* </CarouselIndicators> */}
          {/* <CarouselControl directionText="Prev"
            direction="prev" onClickHandler={onPrev} color="primary" />
          <CarouselControl directionText="Next"
            direction="next" onClickHandler={onNext} color="primary" /> */}
          {/* <button type="button" className="carousel-control-prev" onClick={onPrev}>
              <i className="fa-solid fa-circle-chevron-right fa-3x"></i>
            </button>
            <button type="button" className="carousel-control-next" onClick={onPrev}>
              <i className="fa-solid fa-circle-chevron-left fa-3x"></i>
            </button> */}
        </Carousel >
      </>
    );
  }

  const content = Loading ? <p className="text-center"><i className='fas fa-spinner fa-spin me-2'></i>Loading...</p> : RenderBanners();

  return (
    <Container fluid className="homeBanner">
      {content}
    </Container>
  );
};

export default HomeBanner;
