/* eslint-disable default-case */
import React, { Component } from 'react';
import { Button } from 'reactstrap';
import { Toolbar } from '@syncfusion/ej2-react-grids';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { ToastUtility } from '@syncfusion/ej2-react-notifications';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { HtmlEditor, Image, Inject, Toolbar as rteToolbar, QuickToolbar, RichTextEditorComponent } from '@syncfusion/ej2-react-richtexteditor';
import { confirm } from "react-confirm-box";
import { getAuthToken, getLoginToken } from '../../helpers/authentication';
import { fieldRequired } from '../../helpers/validation';
import { toastNotification } from '../../helpers/toastHelpers';
import { getCurrentDomain } from '../../helpers/lookup';

export class PageContents extends Component {

  constructor(props) {
    super(props);
    this.fullToolbarSettings = {
      items: ['Bold', 'Italic', 'Underline', 'StrikeThrough',
        'FontName', 'FontSize', 'FontColor', 'BackgroundColor',
        'LowerCase', 'UpperCase', '|', 'Formats', 'Alignments', 'OrderedList', 'UnorderedList',
        'Outdent', 'Indent', '|', 'CreateLink', 'Image', '|', 'ClearFormat',
        'SourceCode', 'FullScreen', '|', 'Undo', 'Redo'
      ],
      type: 'Expand'
    };

    this.simpleToolbarSettings = {
      items: ['Bold', 'Italic', 'Underline', '|', 'ClearFormat', 'Undo', 'Redo', '|', 'SourceCode'],
      type: 'Expand'
    };

    // this.iframeSettings = {
    //   enable: true,
    //   resources:
    //   {
    //     scripts: [],
    //     styles: ["/css/harnesspro.css", "/css/adminbootstrap.min.css"]
    //   }
    // };

    this.imageSettings = {
      saveUrl: "api/pagecontents/SaveFile",
      path: getCurrentDomain() + "DynamicData/PageContents/",
      classNames: ["img-fluid"]
    }

    this.state = {
      ddList: [], editData: [], loading: true,
      Id: 0,
      Slug: '',
      Title: '',
      Copy: '',
    };

    this.renderDataTable = this.renderDataTable.bind(this);
    this.onItemChange = this.onItemChange.bind(this);
  }

  componentDidMount() {
    document.title = "Page Contents Administration";
    this.checkLoginStatus();
    this.loadData(0);
  }

  async checkLoginStatus() {
    const loginToken = getLoginToken();
    const bearer = "Bearer " + getAuthToken();

    try {
      const response = await fetch("/api/userlogin/CheckLoginStatus/" + loginToken, {
        method: "GET",
        headers: {
          Authorization: bearer,
          "Content-Type": "application/json"
        }
      });

      if (!response.ok)
        window.location.href = "/login";
    } catch (e) {
      console.error(e);
    }
  }

  editItem = (id) => {
    if (id > 0) {
      const data = this.state.editData.find((item) => { return item.Id === id });
      this.setState({ Id: data.Id, Slug: data.Slug, Title: data.Title, Copy: data.Copy });
    }
    else {
      //clear state fields
      this.setState({
        Id: 0,
        Slug: '',
        Title: '',
        Copy: ''
      });
    }
    this.setState({ editModal: true });
  };

  saveItem = async (event) => {
    event.stopPropagation();
    var valid = true;

    valid &= fieldRequired(this.state.Title, "errTitle", "* Required");
    valid &= fieldRequired(this.state.Copy, "errCopy", "* Required");

    if (valid) {
      this.saveData(this.state.Id);
    }
  }

  deleteItem = async (id) => {
    const confirmStyles = {
      classNames: {
        confirmButton: 'btn btn-sm confirm-button',
        cancelButton: 'btn btn-sm cancel-button',
      }
    }
    const result = await confirm("Are you sure you want to delete this item?", confirmStyles);
    if (result) {
      this.deleteData(id);
    }
  }

  onGridCommand = (args) => {
    switch (args.commandColumn.type) {
      case 'Edit':
        this.editItem(args.rowData.Id);
        break;
      case 'Delete':
        this.deleteItem(args.rowData.Id);
        break;
    }
  }

  onItemChange(e) {

    const Id = e.itemData.Id;
    const content = this.state.editData.find(c => c.Id === Id);
    if (Id > 0 && content) {
      this.setState({
        Id, Title: content.Title, Copy: content.Copy.replace("/DynamicData", getCurrentDomain() + "DynamicData")
      });
    } else {
      this.setState({ Id, Title: "", Copy: "" });
    }
  }

  onImageUploadSuccess(args) {
    if (args.e.currentTarget.getResponseHeader('name') != null) {
      args.file.name = args.e.currentTarget.getResponseHeader('name');
      let filename = document.querySelectorAll(".e-file-name")[0];
      filename.innerHTML = args.file.name.replace(document.querySelectorAll(".e-file-type")[0].innerHTML, '');
      filename.title = args.file.name;
    }
  }

  renderDataTable() {
    return (
      <div className="row">
        <div className="col-12 mb-3">
          <label>Select Item</label>
          <DropDownListComponent dataSource={this.state.editData} fields={{ text: "Title", value: "Id" }} value={this.state.Id} select={this.onItemChange} />
        </div>
        <div className="col-12 mb-3">
          <label>Title  <div id="errTitle" className='Error-Text ps-2' /></label>
          <TextBoxComponent value={this.state.Title} onChange={e => this.setState({ Title: e.target.value })} />
        </div>
        <div className="col-12 mb-3">
          <label>Copy
            <div id="errCopy" className='Error-Text ps-2' />
          </label>
          <RichTextEditorComponent toolbarSettings={this.fullToolbarSettings} insertImageSettings={this.imageSettings} imageUploadSuccess={this.onImageUploadSuccess} height={500} value={this.state.Copy} change={e => this.setState({ Copy: e.value })}>
            <Inject services={[rteToolbar, Toolbar, HtmlEditor, Image, QuickToolbar]} />
          </RichTextEditorComponent>
          <small className="text-muted">NB: Drag corners of the image to resize!</small>
        </div>
        <div className="col-12 text-end">
          <Button color={"success"} size='sm' onClick={this.saveItem}>Save</Button>
        </div>
      </div>
    );
  }

  render() {
    let contents = this.state.loading ? <p className='text-center'><i className='fas fa-spinner fa-spin me-2'></i>Loading...</p> : this.renderDataTable(this.state.editData, this.state.gridCommands, this.state.gridToolbar, this.onGridCommand);

    return (
      <>
        <div className="about-us container">
          <h1>Page Content Administration</h1>
          {contents}
        </div>
      </>

    );
  }

  async loadData(Id) {

    var bearer = 'Bearer ' + getAuthToken();
    try {
      const response = await fetch('api/pagecontents', {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        const editData = [...data];
        // const editData = data;
        let Title = "";
        let Copy = "";
        let content = null;
        if (Id > 0) {
          content = data.find(c => c.Id === Id);

        }
        // else {
        //   content = data[0];
        // }

        if (content) {
          Id = content.Id;
          Title = content.Title;
          Copy = content.Copy.replace("/DynamicData", getCurrentDomain() + "DynamicData");
        }
        this.setState({ Id, Title, Copy, editData, loading: false });
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          this.props.history.push("/login");
      }

    } catch (e) {
      console.error(e);
    }
  }

  async saveData(dataId) {

    this.setState({ loading: true, showError: false, showSuccess: false });

    var bearer = 'Bearer ' + getAuthToken();
    var data = { Id: this.state.Id, Slug: this.state.Slug, Title: this.state.Title, Copy: this.state.Copy };

    try {
      const response = await fetch('api/pagecontents', {
        method: 'POST',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data),
      });
      if (response.ok) {
        const id = await response.json();
        this.loadData(id);
        toastNotification('Page Contents', 'The page contents was successfully saved!', true);
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          this.props.history.push("/login");
      }

    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
      toastNotification('Page Contents', 'There was an error saving the page contents!', false);
    }
  }

  async deleteData(dataId) {
    this.setState({ loading: true });

    var bearer = 'Bearer ' + getAuthToken();
    try {
      const response = await fetch('api/pagecontents/' + dataId, {
        method: 'DELETE',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        await response.json();
        this.loadData(0);
        ToastUtility.show({
          title: 'Page Contents', content: 'The page contents was successfully deleted!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-success'
        });
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          this.props.history.push("/login");
      }
    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
      ToastUtility.show({
        title: 'Page Contents', content: 'There was an error deleting the page contents!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-danger'
      });
    }
  }
}

