/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { loadArticle } from "./GetData";
import { formatDate } from "../helpers/articleHelpers";
import { getCurrentDomain } from "../helpers/lookup";
import { useHistory } from "react-router-dom";

const MediaArticle = () => {
  const [article, setArticle] = useState({});
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const SplitPath = window.location.pathname.split("/");

  if (SplitPath.length < 3 || SplitPath[2] === "") {
    history.push({
      pathname: "/media/latestNews"
    })
  }

  const ArticleSlug = SplitPath[2];
  const articleID = 0;

  useEffect(() => {
    const getArticle = async () => {
      const articleFromServer = await loadArticle(ArticleSlug);
      console.log("Article:", articleFromServer);
      setArticle(articleFromServer);
      setLoading(false);
    };

    getArticle();
  }, []);

  const RenderArticle = () => {

    return (
      <div className="row mediaArticleItem">
        <div className="col-md-1">
          <div className="date px-2 text-center fw-bold me-2">{formatDate(article.ArticleDate)}</div>
        </div>
        <div className="col-md-10">
          <h4>{article.Title}</h4>
          <div className="mediaInfo" dangerouslySetInnerHTML={{ __html: article.Copy.replace(/\/DynamicData/g, getCurrentDomain() + "DynamicData") }}></div>
        </div>
      </div>
    )
  }

  const content = loading ? <p><i className='fas fa-spinner fa-spin me-2'></i>Loading...</p> : RenderArticle();

  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-md-4 mb-4">
            {/* <a href="/media/latestNews" className="btnNewsBack"> <i className="fa fa-arrow-left me-2"></i>Go to Latest News</a> */}
            <a className="btnNewsBack" onClick={() => history.goBack()}><i className="fa fa-arrow-left me-2"></i> Back</a>
          </div>
        </div>
        {content}
        {/* <div className="mediaArticleItem"> */}
        {/* <div className="dateTitleContain">
            <div className="date me-2">{formatDate(article.ArticleDate)}</div>
            <h4>{article.Title}</h4>
          </div>
          <br /> */}
        {/* <div className="mediaInfo">{parseRichTextInArticle(article)}</div> */}

        {/* </div> */}
      </div>
      <br />
    </div>
  );
};

export default MediaArticle;
