import { Link } from 'react-router-dom';
import React, { Component, Fragment } from 'react';

export default class HomeLinkBlocks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ContentList: props.Contents,
    }
  }

  // componentDidMount() {
  //   this.loadData();
  // }

  // async loadData() {
  //   try {
  //     const response = await fetch("/api/pagecontents/home", {
  //       method: "GET",
  //       headers: {
  //         "Content-Type": "application/json"
  //       }
  //     });

  //     if (response.ok) {
  //       const ContentList = await response.json();

  //       this.setState({ ContentList, loading: false });
  //     } else {
  //       console.log(response.status + ": " + response.statusText);
  //     }
  //   } catch (e) {
  //     console.error("Page Content:", e);
  //   }
  // }

  RenderCardText(ContentList, type) {
    switch (type.toLowerCase()) {
      case "innovation":
        return <div className='card-body' dangerouslySetInnerHTML={{ __html: ContentList.find(c => c.Slug === "home-innovation").Copy }}></div>;
      case "quality":
        return <div className='card-body' dangerouslySetInnerHTML={{ __html: ContentList.find(c => c.Slug === "home-quality").Copy }}></div>;
      case "technology":
        return <div className='card-body' dangerouslySetInnerHTML={{ __html: ContentList.find(c => c.Slug === "home-technology").Copy }}></div>;
      default:
        return "";
    }
  }

  render() {

    return (
      <div className="home-link-blocks mb-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 mb-2">
              <div className="card h-100">
                <div className="card-header innovation">
                  <h3>Innovation</h3>
                </div>
                <div className="card-body">
                  {this.RenderCardText(this.state.ContentList, "innovation")}
                </div>
                <Link to="./innovation/manufacturing">
                  <div className="card-footer">
                    <div className="read-more">Read More</div>
                  </div>
                </Link>
              </div>
            </div>

            <div className="col-lg-4 mb-2">
              <div className="card h-100">
                <div className="card-header quality">
                  <h3>Quality</h3>
                </div>
                <div className="card-body">
                  {this.RenderCardText(this.state.ContentList, "quality")}
                </div>
                <Link to="/qualityReports">
                  <div className="card-footer">
                    <div className="read-more">Read More</div>
                  </div>
                </Link>
              </div>
            </div>

            <div className="col-lg-4 mb-2">
              <div className="card h-100">
                <div className="card-header technology">
                  <h3>Technology</h3>
                </div>
                <div className="card-body">
                  {this.RenderCardText(this.state.ContentList, "technology")}
                </div>
                <Link to="/technology">
                  <div className="card-footer">
                    <div className="read-more">Read More</div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

// export default home - link - blocks;

// return (
//   <div className="home-link-blocks">
//     <div className="container my-5">
//       <div className="row">
//         <div className="col-lg-4 mb-2">
//           <div className="card h-100">
//             <div className="card-header innovation">
//               <h3>Innovation</h3>
//             </div>
//             <div className="card-body">
//               HarnessPro also manufactures standard or one-off Box and Panel
//               Assemblies for electrical enclosures, both from metal sheeting
//               and plastic. These enclosures can be manufactured and equipped
//               with wiring and control circuits according to IP65 or similar
//               specifications.
//             </div>
//             <Link to="./innovation/manufacturing">
//               <div className="card-footer">
//                 <div className="read-more">Read More</div>
//               </div>
//             </Link>
//           </div>
//         </div>

//         <div className="col-lg-4 mb-2">
//           <div className="card h-100">
//             <div className="card-header quality">
//               <h3>Quality</h3>
//             </div>
//             <div className="card-body">
//               At HarnessPro, quality is the cornerstone of our processes,
//               product and service. Our culture is that quality is built into
//               each step of the manufacturing process of a harness, leading to
//               a superior quality product to our customer. To consistently
//               achieve first-rate quality, we make use of the latest
//               Manufacturing and Testing technology. Our harness Operators are
//               certified and work to the international harness and looming
//               manufacturing standard: IPC620 - Class 3, supporting our zero
//               compromise to quality from the first stage of the manufacturing
//               process to the final process. We are ISO 9001:2015 accredited,
//               which align us to the latest Quality Management System.
//             </div>
//             <Link to="/qualityReports">
//               <div className="card-footer">
//                 <div className="read-more">Read More</div>
//               </div>
//             </Link>
//           </div>
//         </div>

//         <div className="col-lg-4 mb-2">
//           <div className="card h-100">
//             <div className="card-header technology">
//               <h3>Technology</h3>
//             </div>
//             <div className="card-body">
//               In addition to manufacturing from detailed engineering drawings
//               and schematics, we have the ability to design or reverse
//               engineer harnesses to customer-specific requirements employing
//               both Vesys and AutoCad. We specialise in the following types of
//               harnesses:
//               <br />• Open-wire Bundle Harnesses
//               <br />• Enclosed Harnesses
//               <br />• Braided Harnesses
//             </div>
//             <Link to="/technology">
//               <div className="card-footer">
//                 <div className="read-more">Read More</div>
//               </div>
//             </Link>
//           </div>
//         </div>
//       </div>
//     </div>
//   </div>
// );
// };

// export default HomeLinks;
