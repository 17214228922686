export const getAuthToken = () => {
  if (window.localStorage.getItem("AuthToken") != null) {
    var data = JSON.parse(window.localStorage.getItem("AuthToken"));
    //todo: check expiry
    // console.log("Got Auth Token " + window.localStorage.getItem("AuthToken"))


    if (GetTimeDifference(data.authExpires) < 1)
      return data.authToken;
    else
      return "";
  }
  else
    return null;
};

export const getLoginToken = () => {
  if (window.localStorage.getItem("LoginToken") != null)
    return window.localStorage.getItem("LoginToken");
  else
    return null;
}

export const setLoginToken = (data) => {
  try {
    window.localStorage.setItem("LoginToken", data);
    return true;
  } catch (e) {
    return false;
  }
}

const GetTimeDifference = (expiryDate) => {
  expiryDate = new Date(expiryDate);
  const CurrentDate = new Date();
  const timeDifference = CurrentDate - expiryDate;
  const hourDifference = timeDifference / (1000 * 60 * 60 * 24);
  return hourDifference;
}

export const setAuthToken = (token, expiry) => {
  const data = { authToken: token, authExpires: expiry }
  try {
    window.localStorage.setItem("AuthToken", JSON.stringify(data));
    console.log("Auth token set - " + JSON.stringify(data))
    return true;
  } catch (e) {
    console.error(e)
    return false;
  }
};

export const getUserDetails = () => {
  if (window.localStorage.getItem("CurrentUser") != null)
    return JSON.parse(window.localStorage.getItem("CurrentUser"));
  else
    return null;
};

export const setUserDetails = (props) => {
  try {
    window.localStorage.setItem("CurrentUser", JSON.stringify(props));
    return true;
  } catch (e) {
    console.error(e)
    return false;
  }

};

export const getUserLevel = () => {
  if (window.localStorage.getItem("AuthLevel") != null)
    return window.localStorage.getItem("AuthLevel");
  else
    return null;
};

export const setUserLevel = (level) => {
  try {
    window.localStorage.setItem("AuthLevel", level);
    return true;
  } catch (e) {
    console.error(e)
    return false;
  }
};

export const clearUserDetails = () => {
  window.localStorage.removeItem("CurrentUser");
  window.localStorage.removeItem("AuthToken");
  window.localStorage.removeItem("AuthLevel");
  window.localStorage.removeItem("LoginToken");
};