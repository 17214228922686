String.prototype.toNumberString = function(defaultValue) {
    if (this === null || this === "")
        return defaultValue;
    try {
        var check = parseFloat(this).toFixed(2).toString();
        var x = check.split('.');
        var x1 = x[0];
        var x2 = x.length > 1 ? '.' + x[1] : '';
        var rgx = /(\d+)(\d{3})/;
        while (rgx.test(x1)) {
            x1 = x1.replace(rgx, '$1' + ' ' + '$2');
        }
        return x1 + x2;
    } catch (e) {
        return defaultValue;
    }
};

String.prototype.trim = function() {
    return this.replace(/^\s+|\s+$/g, '');
};

String.prototype.ltrim = function() {
    return this.replace(/^\s+/, '');
};

String.prototype.rtrim = function() {
    return this.replace(/\s+$/, '');
};

String.prototype.fulltrim = function() {
    return this.replace(/(?:(?:^|\n)\s+|\s+(?:$|\n))/g, '').replace(/\s+/g, ' ');
}

String.prototype.lpad = function(padString, length) {
    var str = this;
    while (str.length < length)
        str = padString + str;
    return str;
}

String.prototype.toSystemDate = function() {
    if (this === null || this === '')
        return "";
    try {
        var check = new Date(this);

        return check.getFullYear().toString() + "-" + (check.getMonth() + 1).toString().lpad('0', 2) + "-" + check.getDate().toString().lpad('0', 2);
    } catch (e) {
        return "";
    }
};

String.prototype.toSystemDateTime = function() {
    if (this === null || this === '')
        return "";
    try {
        var check = new Date(this);

        return check.getFullYear().toString() + "-" + (check.getMonth() + 1).toString().lpad('0', 2) + "-" + check.getDate().toString().lpad('0', 2) + " " + check.getHours().toString().lpad('0', 2) + ":" + check.getMinutes().toString().lpad('0', 2) + ":" + check.getSeconds().toString().lpad('0', 2);
    } catch (e) {
        return "";
    }
};