import { useEffect, useState } from "react";
import { getCurrentDomain } from "../helpers/lookup";

const QualityReport = () => {

  const [Slides, setSlides] = useState([]);
  const [Loading, setLoading] = useState(true);

  useEffect(() => {
    loadData();
  }, [Loading]);

  const loadData = async () => {
    try {
      const response = await fetch("api/technologyslides/GetSlides/2", {
        method: "GET",
        withCredentials: true,
        credentials: "include",
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (response.ok) {
        const data = await response.json();

        setSlides(data);
        setLoading(false);
      } else {

        console.log(response.status + ": " + response.statusText);
        setLoading(false);
      }
    } catch (e) {
      console.error(e);
      setLoading(false);
    }
  }


  const RenderSlides = () => {

    return (
      <div className="carouselContain m-md-auto m-0">
        <div
          id="qualityReportsCarousel"
          className="carousel slide"
          data-bs-ride="carousel"
        >
          <div className="carousel-inner">
            {
              Slides.map((item, idx) =>
                <div key={idx} className={"carousel-item " + (idx > 0 ? "" : "active")}>
                  <img
                    src={getCurrentDomain() + item.Image}
                    className="w-100 img-fluid"
                    alt={item.Title}
                  />

                  <div className="text-overlay d-none d-md-block">
                    <div className="carousel-caption ">
                      <h5>{item.Title}</h5>
                      <div dangerouslySetInnerHTML={{ __html: item.Description }} />
                    </div>
                  </div>
                  <div className="col-12 mt-3 d-block d-md-none">
                    <h5>{item.Title}</h5>
                    <div dangerouslySetInnerHTML={{ __html: item.Description }} />
                  </div>
                </div>
              )
            }
          </div>
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#qualityReportsCarousel"
            data-bs-slide="prev"
          >
            <span
              className="arrowIcon"
              aria-hidden="true"
            >
              <i className="fas fa-chevron-left fa-2x mt-2 pt-1" ></i>
            </span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#qualityReportsCarousel"
            data-bs-slide="next"
          >
            <span
              className="arrowIcon"
              aria-hidden="true"
            >
              <i className="fas fa-chevron-right fa-2x mt-2 pt-1" ></i>
            </span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </div>

    )

  }

  const content = Loading ? <p><i className='fas fa-spinner fa-spin me-2'></i>Loading...</p> : RenderSlides();

  return (
    <div>
      <div className="container pb-5">
        <h1 className="mb-3">Quality & Testing</h1>
        <p>At HarnessPro we comply with strict ISO 9001:2015 quality standards and are focused on
          continuous improvement, ensuring that our products are manufactured and tested to the latest
          and highest quality standards.</p>

        {content}
      </div>
    </div>
  );
};

export default QualityReport;
