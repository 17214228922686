/* eslint-disable default-case */
import { Link } from "react-router-dom";

const HomeAbout = (props) => {


  const RenderContentText = (type) => {

    let content = "";
    switch (type.toLowerCase()) {
      case "objectives":
        content = props.Contents.find(c => c.Slug === "home-company-objectives");
        break;
      case "whoarewe":
        content = props.Contents.find(c => c.Slug === "home-who-are-we");
        break;
    }

    if (content)
      return <div dangerouslySetInnerHTML={{ __html: content.Copy }}></div>
    else
      return <div></div>
  }
  return (
    <div className="container home-about-us my-5">
      <div className="row g-0 mx-auto">
        <div className="col-lg-8">
          <h1 className="mb-2">About Us</h1>
          <div className="back-g objectives p-4 mb-5">
            {RenderContentText("objectives")}

          </div>
          <button type="button" onClick={() => window.location.href = "/aboutUs"} className="btn-submit btn-sm">
            Read More
          </button>
        </div>
        <div className="col-lg-4 whoWeAre">
          <div className="p-4 text-white pt-4">
            <h3>Who Are We</h3>
            {RenderContentText("whoarewe")}
          </div>
        </div>
        {/* <div className="col-lg-8">
          <h1 className="">About Us</h1>
          <div className="back-g objectives">
            <div className="objectivesContent">
              {/* <h5 className="pb-3">Company Objectives</h5> */}
        {/* {RenderContentText("objectives")} */}
        {/* <Link to="/aboutUs"> */}

        {/* </Link> *s/}
            </div>
          </div>
        </div>
        <div className="col-lg-4 text-white background  whoWeAre">
          <div className="whoWeAreContain">
            <h3>Who Are We</h3>
            {RenderContentText("whoarewe")}
            {/* <Link to="/aboutUs" > *s/}
            <button onClick={() => window.location.href = "/aboutus"} className="btn-submit whoWeAreBtn">
              <b>Read More</b>
            </button>
            {/* </Link> *s/}
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default HomeAbout;
