import { useEffect, useState } from "react";

const TermsAndConditions = () => {

  const [terms, setTerms] = useState([]);
  const [loading, setLoaidng] = useState(true);

  useEffect(() => {
    loadData();
  }, [loading])


  const loadData = async () => {

    try {
      const response = await fetch("api/pagecontents/GetContentByPage/terms", {
        method: "GET",
        withCredentials: true,
        credentials: "include",
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (response.ok) {
        const data = await response.json();
        setTerms(data);
        setLoaidng(false);
      } else {
        setLoaidng(false);
      }
    } catch (e) {
      console.error(e);
      setLoaidng(false);
    }
  }

  const RenderTerms = () => {
    return (
      <div dangerouslySetInnerHTML={{ __html: terms[0].Copy }}></div>
    )
  }

  const content = loading ? <p><i className='fas fa-spinner fa-spin me-2'></i>Loading...</p> : RenderTerms();

  return (
    <div className="container pb-4">
      {content}
    </div>
  );
}

export default TermsAndConditions;
