/* eslint-disable eqeqeq */
import React, { Component } from "react";
import { getCurrentDomain } from "../helpers/lookup";

export class InnovationManufacturing extends Component {
  constructor(props) {
    super(props);
    this.state = { InnovationList: [], selectedItem: 0, selectedImg: "", selectedName: "", loading: true };
  }

  componentDidMount() {
    this.loadData();
  }


  async loadData() {
    try {
      const response = await fetch("api/innovations/GetInnovations", {
        method: "GET",
        withCredentials: true,
        credentials: "include",
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (response.ok) {

        let data = await response.json();
        let selectedName = "";
        let selectedImg = "";
        let selectedItem = 0;

        data.sort((a, b) => a.DisplayOrder - b.DisplayOrder)

        if (data.length > 0) {
          selectedItem = data[0].Id;
          selectedName = data[0].Title;
          selectedImg = data[0].ImageName;
        }
        this.setState({ InnovationList: data, selectedItem, selectedName, selectedImg, loading: false })
      } else {
        console.log(response.status + ": " + response.statusText);
      }
    } catch (e) {
      console.error(e);
    }
  }

  showImage = (event) => {
    event.stopPropagation();
    const id = event.currentTarget.getAttribute("data-target");

    const item = this.state.InnovationList.find(i => i.Id == id);
    console.log(this.state.InnovationList, item);

    if (item)
      this.setState({ selectedItem: id, selectedImg: item.ImageName, selectedName: item.Title });

    // const src = $("#" + id).attr("src");
    // const alt = $("#" + id).attr("alt");
    // $("#imHero").attr("src", img).attr("alt", img);
    //event.currentTarget.
    //$(this).addClass("active")
  }

  renderContents(selectedItem, Contents) {
    return (
      <div>
        {Contents.map((item, idx) =>
          <div key={idx}>
            <button
              className={`${selectedItem == item.Id
                ? "active"
                : ""
                }`}
              data-target={item.Id}
              onClick={this.showImage}
            >
              {item.Title}
            </button>
            <img
              id="imCuttingToSizeStripping"
              src={getCurrentDomain() + item.ImageName}
              className={"img-fluid d-md-none"}
              alt="Cutting to Size & Stripping"
            />
          </div>
        )
        }
      </div>
    )
  }

  render() {
    const content = this.state.loading ? <p><i className='fas fa-spinner fa-spin me-2'></i>Loading...</p> : this.renderContents(this.state.selectedItem, this.state.InnovationList)
    return (
      <div>
        <div className="innovationManufacturingContainer container">
          <h1>Manufacturing &amp; Engineering Capabilities</h1>
          <div className="row  mb-5">
            <div className="col-md-4 col-lg-3 innovation-links">
              {content}
            </div>
            <div className="col-md-8 col-lg-9 align-self-center">
              <img
                id="imHero"
                src={getCurrentDomain() + this.state.selectedImg}
                className="img-fluid d-none d-md-inline"
                alt={this.state.selectedName}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
