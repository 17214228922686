import React, { Component } from "react";
import {
  Button,
  Modal,
  ModalFooter,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
} from "reactstrap";
import { confirm } from "react-confirm-box";
import { getAuthToken } from "../../helpers/authentication";
import "../../helpers/string-prototypes";
import { toastNotification } from "../../helpers/toastHelpers";
import { fieldRequired } from "../../helpers/validation";
import ImageEditor from "./ImageEditor";

export class Banners extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editData: [],
      loading: true,
      editModal: false,
      showSuccess: false,
      showError: false,
      showDelete: false,
      Id: 0,
      SiteSection: 0,
      Title: "",
      Filename: "",
      StartDate: new Date(),
      EndDate: new Date(),
      ActionData: "",
      FileData: "",
      ExistingFile: ""

    };
    this.toggle = this.toggle.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);

  }

  toggle() {
    this.setState({
      editModal: !this.state.editModal,
    });
  }

  componentDidMount() {
    document.title = "Banners Administration";
    this.loadData();
  }

  editItem = (event) => {
    event.stopPropagation();
    const id = event.currentTarget.getAttribute("data-recordid");

    if (id > 0) {
      const data = this.state.editData.find((item) => {
        return item.Id === parseInt(id);
      });
      this.setState({
        Id: data.Id,
        Title: data.Title,
        Filename: data.Filename,
        StartDate: data.StartDate,
        EndDate: data.EndDate,
        ActionData: data.ActionData,
        ExistingFile: data.Filename.length > 0 ? data.Filename.split("\\")[2] : ""
      });
    } else {
      //clear state fields
      this.setState({
        Id: 0,
        Title: "",
        Filename: "",
        FileData: "",
        ExistingFile: "",
        StartDate: new Date(),
        EndDate: null,
        ActionData: ""
      });
    }
    this.setState({
      editId: id,
      editModal: true,
      showError: false,
      showSuccess: false,
    });
  };

  saveItem = async (event) => {
    event.stopPropagation();
    let valid = true;
    valid &= fieldRequired(this.state.Title, "errTitle", "* required");
    valid &= fieldRequired(this.state.StartDate, "errStartDate", "* required");

    if (valid) {
      this.saveData(this.state.editId);
      this.setState({ editModal: false });
    }
  };

  deleteItem = async (event) => {
    event.stopPropagation();
    this.setState({ showError: false, showSuccess: false });
    const id = event.currentTarget.getAttribute("data-recordid");
    const result = await confirm("Are you sure you want to delete this item?");
    if (result) {
      this.deleteData(id);
    }
  };

  static RenderNullDate(date) {
    if (date) {
      return date.toSystemDate();
    }
    else
      return "-";
  }

  static renderDataTable(data, editFunction, deleteFunction) {
    return (
      <table className="table table-striped" aria-labelledby="tabelLabel">
        <thead>
          <tr>
            <th>Title</th>
            <th>Start Date</th>
            <th>End Date</th>
            <th className="text-end">Actions</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item) => (
            <tr key={item.Id}>
              <td>{item.Title}</td>
              <td>{Banners.RenderNullDate(item.StartDate)}</td>
              <td>{Banners.RenderNullDate(item.EndDate)}</td>
              <td className="text-end">
                <div className="btn-group">
                  <Button
                    color="dark"
                    outline
                    size="sm"
                    data-toggle="tooltip"
                    data-placement="auto"
                    data-recordid={item.Id}
                    title="Edit"
                    onClick={editFunction}
                  >
                    <i className="far fa-edit"></i>
                  </Button>
                  <Button
                    color="dark"
                    outline
                    size="sm"
                    data-toggle="tooltip"
                    data-placement="auto"
                    data-recordid={item.Id}
                    title="Delete"
                    onClick={deleteFunction}
                  >
                    <i className="far fa-trash-alt text-danger"></i>
                  </Button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }

  handleFileChange(Filename, FileData) {
    this.setState({
      Filename, FileData
    });
  }


  render() {
    let contents = this.state.loading ? (
      <p>
        <em>Loading...</em>
      </p>
    ) : (
      Banners.renderDataTable(
        this.state.editData,
        this.editItem,
        this.deleteItem
      )
    );

    return (
      <>
        <div className="container">
          <Row>
            <Col xs={8}>
              <h1>Banners Administration</h1>
            </Col>
            <Col xs={4} className="text-end align-self-center">
              <Button
                color="primary"
                size="sm"
                data-recordid="0"
                onClick={this.editItem}
              >
                Add New <i className="fas fa-plus-circle ms-2"></i>
              </Button>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>{contents}</Col>
          </Row>
        </div>
        <Modal
          isOpen={this.state.editModal}
          toggle={this.toggle}
          className={this.props.className}
          scrollable
          size="lg"
          backdrop="static"
        >
          <ModalHeader
            toggle={this.toggle}
            close={
              <button className="close" onClick={this.toggle}>
                <i className="fas fa-times"></i>
              </button>
            }
          >
            Edit Banners
          </ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup className="mb-3">
                <Label for="tbTitle">Title
                  <div id="errTitle" className="ErrorText"></div>
                </Label>
                <Input
                  id="tbTitle"
                  name="tbTitle"
                  className="form-control"
                  placeholder="Title"
                  type="text"
                  maxLength="350"
                  required
                  value={this.state.Title}
                  onChange={(e) => this.setState({ Title: e.target.value })}
                />
              </FormGroup>
              <div className="row">
                <FormGroup className="mb-3 col-6">
                  <Label for="tbStartDate">Start Date
                    <div id="errStartDate" className="ErrorText"></div>
                  </Label>
                  <Input
                    id="tbStartDate"
                    name="tbStartDate"
                    className="form-control"
                    type="date"
                    required
                    value={this.state.StartDate.toString().toSystemDate()}
                    // defaultValue={}
                    onChange={(e) =>
                      this.setState({ StartDate: e.target.value })
                    }
                  />
                </FormGroup>

                <FormGroup className="mb-3 col-6">
                  <Label for="tbEndDate">End Date</Label>
                  <Input
                    id="tbEndDate"
                    name="tbEndDate"
                    className="form-control"
                    type="date"
                    value={this.state.EndDate ? this.state.EndDate.toString().toSystemDate() : ""}
                    onChange={(e) => this.setState({ EndDate: e.target.value })}
                  />
                </FormGroup>
              </div>

              <FormGroup className="mb-3" >
                <Label for="tbActionData">Link Url</Label>
                <Input
                  id="tbActionData"
                  name="tbActionData"
                  className="form-control"
                  placeholder="Link Url"
                  type="text"
                  maxLength="350"
                  required
                  value={this.state.ActionData}
                  onChange={(e) =>
                    this.setState({ ActionData: e.target.value })
                  }
                />
              </FormGroup>

              <FormGroup>
               <ImageEditor LabelText={"Banner Image"} FullWidth={true} ExistingImage={this.state.Filename} OnChangeMethod={this.handleFileChange} />
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="dark" size="sm" onClick={this.toggle}>
              Cancel <i className="far fa-times-circle ms-2"></i>
            </Button>
            <Button color="success" size="sm" onClick={this.saveItem}>
              Save <i className="far fa-check-circle ms-2"></i>
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }

  imageEditorCreated() {
   
  }

  async loadData() {
    var bearer = "Bearer " + getAuthToken();
    try {
      const response = await fetch("api/banners", {
        method: "GET",
        withCredentials: true,
        credentials: "include",
        headers: {
          Authorization: bearer,
          "Content-Type": "application/json",
        },
      });
      if (response.ok) {
        const data = await response.json();
        this.setState({ editData: data, loading: false });
      } else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401) this.props.history.push("/login");
      }
    } catch (e) {
      console.error(e);
    }
  }

  async saveData(dataId) {
    this.setState({ loading: true, showError: false, showSuccess: false });

    var bearer = "Bearer " + getAuthToken();
    var data = {
      Id: this.state.Id,
      Title: this.state.Title,
      Filename: this.state.Filename,
      StartDate: this.state.StartDate,
      EndDate: this.state.EndDate,
      ActionData: this.state.ActionData,
      FileData: this.state.FileData
    };

    try {
      const response = await fetch("api/banners", {
        method: "POST",
        withCredentials: true,
        credentials: "include",
        headers: {
          Authorization: bearer,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      await response.json();
      this.loadData();
      this.setState({ showSuccess: true });
      toastNotification(
        "Banners",
        "The banner was successfully saved!",
        true
      );
    } catch (e) {
      console.error(e);
      this.setState({ loading: false, showError: true });
      toastNotification(
        "User Data",
        "There was an error saving the banner!",
        false
      );
    }
  }

  async deleteData(dataId) {
    this.setState({ loading: true });

    var bearer = "Bearer " + getAuthToken();
    try {
      const response = await fetch("api/banners/" + dataId, {
        method: "DELETE",
        withCredentials: true,
        credentials: "include",
        headers: {
          Authorization: bearer,
          "Content-Type": "application/json",
        },
      });
      await response.json();
      this.loadData();
      this.setState({ showDelete: true });
      toastNotification(
        "Banners",
        "The banner was successfully deleted!",
        true
      );
    } catch (e) {
      console.error(e);
      this.setState({ loading: false, showError: true });
      toastNotification(
        "User Data",
        "There was an error when deleting the banner!",
        false
      );
    }
  }
}
