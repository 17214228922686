import { getUserDetails } from "./authentication";

export const getCurrentDomain = () => {
  const NODE_ENV = process.env.NODE_ENV || "development"
  if (NODE_ENV === "development")
    return "https://localhost:7211/";
  else
    return "/";
}

export const getEditId = () => {
  if (window.localStorage.getItem("DataEditId")) {
    var data = JSON.parse(window.localStorage.getItem("DataEditId"));
    return Number(data);
  }
  else
    return 0;
};

export const setEditId = (DataEditId) => {
  try {
    window.localStorage.setItem("DataEditId", JSON.stringify(DataEditId));
    return true;
  } catch (e) {
    console.error(e)
    return false;
  }
};

export const getEditData = () => {
  if (window.localStorage.getItem("EditData")) {
    var data = JSON.parse(window.localStorage.getItem("EditData"));
    return Number(data);
  }
  else
    return 0;
};

export const setEditData = (DataEdit) => {
  try {
    window.localStorage.setItem("EditData", JSON.stringify(DataEdit));
    return true;
  } catch (e) {
    console.error(e)
    return false;
  }
};









