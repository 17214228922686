/* eslint-disable eqeqeq */
import React from "react";
import { useHistory } from "react-router-dom";
import { formatDate } from "../helpers/articleHelpers";
import { getCurrentDomain } from "../helpers/lookup";

const MediaItem = ({ articleID, articleSlug, articleDate, title, snippet, thumbnail }) => {
  const history = useHistory();

  // const handleClick = (id) => {
  //   });
  // };

  const getThumbnail = () => {

    if (thumbnail && thumbnail != "")
      return getCurrentDomain() + thumbnail;
    else
      return "https://via.placeholder.com/400x250?text=" + title.replace(" ", "+");
  }
  return (
    <>
      <div className="row mediaItem my-3">
        <div className="col-md-1 date">{formatDate(articleDate)}</div>
        <div className="col-md-4 mediaImg" style={{ minHeight: "300px" }}>
          <img src={getThumbnail()} className="img-fluid w-100" style={{ maxHeight: "300px" }} alt={title} />
        </div>
        <div className="col-md-7 d-flex flex-column">
          <div className="mb-auto">
            <h4>{title}</h4>
            <div dangerouslySetInnerHTML={{ __html: snippet }}></div>
          </div>
          <div className="mt-auto">
            {/* <button
            type="button"
            className="read-more-btn mt-auto"
            onClick={() => handleClick(articleID)}
          >
            Read More
          </button> */}
            <a href={"/mediaArticle/" + articleSlug} className="btn-read-more mt-auto">Read More</a>
          </div>
        </div>
      </div>
      <hr className="border-0 p-2" />
    </>
  );
};

export default MediaItem;
