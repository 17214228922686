import React, { Component} from "react";
import {
  Collapse,
  Container,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
} from "reactstrap";
import { Link } from "react-router-dom";
import { getAuthToken } from "../../helpers/authentication";
import { toastNotification } from "../../helpers/toastHelpers";
//import './NavMenu.css';

export class NavMenu extends Component {
  static displayName = NavMenu.name;

  constructor(props) {
    super(props);

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      collapsed: true,
      menuItems: []
    };
  }

  componentDidMount() {
    this.loadData()
  }

  toggleNavbar() {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  }


  render() {

    return (
      <>
        <header className="harnessHeader">
          <Navbar className="navbar navbar-expand-lg ng-white mb-3" light>
            <Container>
              <NavbarBrand tag={Link} to="/">
                <img src="/img/global/harnesspro-logo.png" className="img-fluid" alt="Harnesspro Logo" />
              </NavbarBrand>
              <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
              <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>
                <ul className="navbar-nav flex-grow">
                  {/*<NavItem>*/}
                  {/*  <NavLink tag={Link} className="text-dark" to="/">Home</NavLink>*/}
                  {/*</NavItem>*/}
                  <UncontrolledDropdown inNavbar nav>
                    <DropdownToggle caret nav>
                      Innovation
                    </DropdownToggle>
                    <DropdownMenu right>
                      <DropdownItem>
                        <NavLink tag={Link} className="text-dark" to="/innovation/manufacturing">
                          Manufacturing &<br />
                          Engineering Capabilities
                        </NavLink>
                      </DropdownItem>
                      <DropdownItem>
                        <NavLink tag={Link} className="text-dark" to="/innovation/supply">
                          International Supply
                        </NavLink>
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>

                  <NavItem>
                    <NavLink tag={Link} className="text-dark" to="/qualityreports">
                      Quality & Testing
                    </NavLink>
                  </NavItem>

                  <NavItem>
                    <NavLink tag={Link} className="text-dark" to="/technology">
                      Technology
                    </NavLink>
                  </NavItem>

                  <UncontrolledDropdown inNavbar nav>
                    <DropdownToggle caret nav>
                      Industries
                    </DropdownToggle>
                    <DropdownMenu right>
                      {this.state.menuItems.map((item, index) => {
                        return <DropdownItem key={index}>
                          <a className=" nav-link text-dark" href={`/industries/${item.Title.replaceAll(" ", "")}`}>
                            {item.Title}
                          </a>
                        </DropdownItem>
                      })}
                    </DropdownMenu>
                  </UncontrolledDropdown>

                  <UncontrolledDropdown inNavbar nav>
                    <DropdownToggle caret nav>
                      About Us
                    </DropdownToggle>
                    <DropdownMenu right>
                      <DropdownItem>
                        <NavLink tag={Link} className="text-dark" to="/aboutus">
                          About Us
                        </NavLink>
                      </DropdownItem>
                      <DropdownItem>
                        <NavLink tag={Link} className="text-dark" to="/qms">
                          QMS
                        </NavLink>
                      </DropdownItem>
                      <DropdownItem>
                        <NavLink
                          tag={Link}
                          className="text-dark"
                          to="/termsandconditions"
                        >
                          Terms & Conditions
                        </NavLink>
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>

                  <UncontrolledDropdown inNavbar nav>
                    <DropdownToggle caret nav>
                      Media
                    </DropdownToggle>
                    <DropdownMenu right>
                      <DropdownItem>
                        <NavLink
                          tag={Link}
                          className="text-dark"
                          to="/media/latestnews"
                        >
                          Latest News
                        </NavLink>
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>

                  <NavItem>
                    <NavLink tag={Link} className="text-dark" to="/contactus">
                      Contact Us
                    </NavLink>
                  </NavItem>
                </ul>
              </Collapse>
            </Container>
          </Navbar>

          <div className="header-seperator">
            <div className="container">
              <div className="row">
                <div className="col-12 text-end">
                  <img
                    src="/img/global/seperator-block.png"
                    alt="Seperator Block"
                  />
                </div>
              </div>
            </div>
          </div>
        </header>
      </>
    );
  }

  async loadData() {
    try {
      var bearer = 'Bearer ' + getAuthToken();

      const response = await fetch('/api/industries', {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        },
      });

      if (response.ok) {
        const body = await response.json()
        this.setState({ menuItems: body })
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          this.props.history.push("/login");
      }
    } catch (error) {
      toastNotification('Industries', 'There was an error loading the Industries!', false);
    }
  }
}
