import { ToastUtility } from "@syncfusion/ej2-react-notifications";

export function toastNotification(title, content, isSuccessful) {
  ToastUtility.show({
    title,
    content,
    timeOut: 5000,
    position: {
      X: "Right",
      Y: "Top",
    },
    showCloseButton: true,
    cssClass: isSuccessful ? "e-toast-success" : "e-toast-danger",
  });
}
