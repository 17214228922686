import { useEffect, useState } from "react";
import { toastNotification } from "../helpers/toastHelpers";
import { getAuthToken } from "../helpers/authentication";
import { getCurrentDomain } from "../helpers/lookup";

const QMS = () => {
  function removeTags(str) {
    return str?.replace(/<[^>]*>|&nbsp;/g, '')
  }
  async function getQMS() {
    try {
      var bearer = 'Bearer ' + getAuthToken();

      const response = await fetch('/api/qms', {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        },
      });

      if (response.ok) {
        const body = await response.json()
        setCertificates(body)
        setLoading(false)
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          this.props.history.push("/login");
      }
    } catch (error) {
      toastNotification('QMS', 'There was an error loading the qms!', false);
    }
  }

  useEffect(() => {
    getQMS()
  }, [])

  const [certificates, setCertificates] = useState([])
  const [loading, setLoading] = useState(true)

  return (
    <div className="about-us_qms">
      <div className="container">
        <h1>QMS</h1>
        <div className="pb-2 text">
          <p>To download the certificate, please click on the links</p>
        </div>

        <div className="pb-5">
          {loading ? <div className="d-flex justify-content-center align-items-center">
            <i className="fa fa-spinner mx-2"></i> loading
          </div> : certificates.length ? certificates.map((item, index) => {
            return <div className="d-flex align-items-center pb-4" key={index}>
              <div className="flex-shrink-0">
                <img
                  className="img-fluid"
                  src={getCurrentDomain() + item.Thumbnail}
                  alt={item.ThumbnailName}
                />
              </div>

              <div className="flex-grow-1 ms-3">
                <a href={getCurrentDomain() + item.StoredFile} target="_blank" rel='noreferrer' download>
                  <span className="link-heading">
                    {item.Title}
                  </span>
                  <br />
                  {removeTags(item.Description)}
                </a>
              </div>
            </div>
          }) : "NO CERTIFICATES TO DOWNLOAD"}
          {/* <div className="d-flex align-items-center pb-4">
            <div className="flex-shrink-0">
              <img
                className="img-fluid"
                src="img\qms\image-placeholder.png"
                alt="International QMS"
              />
            </div>

            <div className="flex-grow-1 ms-3">
              <Link to="url" target="_blank" download>
                <span className="link-heading">
                  International Quality Management System
                </span>
                <br />
                ISO 9001:2015
              </Link>
            </div>
          </div>

          <div className="d-flex align-items-center pb-4">
            <div className="flex-shrink-0">
              <img
                className="img-fluid"
                src="img\qms\image-placeholder.png"
                alt="IPC 620"
              />
            </div>

            <div className="flex-grow-1 ms-3 .link">
              <Link to="url" target="_blank" download>
                <span className="link-heading">IPC 620</span>
                <br />
                Workmanship
              </Link>
            </div>
          </div>

          <div className="d-flex align-items-center">
            <div className="flex-shrink-0">
              <img
                className="img-fluid"
                src="img\qms\image-placeholder.png"
                alt="BEE Compliance Certificate"
              />
            </div>
            <div className="flex-grow-1 ms-3 .link">
              <Link to="url" target="_blank" download>
                <span className="link-heading">BEE</span>
                <br />
                Compliance Certificate
              </Link>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};



export default QMS;
