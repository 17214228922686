import React from "react";
import { useState, useEffect } from "react";
import { loadData } from "./GetData";
import MediaItem from "./MediaItem";

const MediaLatestNews = () => {
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getArticles = async () => {
      const articlesFromServer = await loadData('newsarticles/GetArticles');
      setArticles(articlesFromServer);
      setLoading(false);
    };

    getArticles();
  }, []);

  const RenderArticles = () => {
    var viewableArticles = [];
    if (articles) {
      viewableArticles = articles.slice(0, 5);
    }

    return (

      viewableArticles.length > 0 ?
        viewableArticles.map((article) => (
          <MediaItem
            key={article.Id}
            articleID={article.Id}
            articleSlug={article.Slug}
            articleDate={article.ArticleDate}
            title={article.Title}
            snippet={article.Snippet}
            thumbnail={article.Thumbnail}
          />
        ))
        :
        <p>There are currently no news articles available. Please check later.</p>
    )
  }

  const content = loading ? <p><i className='fas fa-spinner fa-spin me-2'></i>Loading...</p> : RenderArticles();


  return (
    <div>
      <div className="container mb-5">
        <h1>Media</h1>
        <div className="mediaItems mt-5">
          {content}
        </div>
      </div>
    </div>
  );
};

export default MediaLatestNews;
