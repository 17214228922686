/* eslint-disable no-loop-func */
import { ColumnDirective, ColumnsDirective, CommandColumn, Edit, GridComponent, Sort, Toolbar } from '@syncfusion/ej2-react-grids';
import { ToastUtility } from '@syncfusion/ej2-react-notifications';
import { Inject } from '@syncfusion/ej2-react-richtexteditor';
import React, { Component } from 'react';
import { confirm } from "react-confirm-box";
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { getAuthToken, getLoginToken } from '../../helpers/authentication';
import { toastNotification } from '../../helpers/toastHelpers';

export class ContactMessages extends Component {

  constructor(props) {
    super(props);
    const commandTemplate = [
      { type: 'View', buttonOption: { cssClass: 'e-flat', iconCss: 'e-eye e-icons' } },
      { type: 'Delete', buttonOption: { cssClass: 'e-flat', iconCss: 'e-delete e-icons' } }
    ];



    this.state = {
      editData: [], reasonData: [], loading: true, editModal: false, gridCommands: commandTemplate, gridToolbar: ['Search'],
      Id: 0,
      UserId: 0,
      ReasonId: 0,
      Reason: '',
      Firstname: '',
      Surname: '',
      Email: '',
      Mobile: '',
      IPAddress: '',
      Message: ''
    };
    this.toggle = this.toggle.bind(this);

  }

  toggle() {
    this.setState({
      editModal: !this.state.editModal
    });
  }

  componentDidMount() {
    document.title = "Contact Messages Administration";
    this.checkLoginStatus();
    this.loadData();
  }

  async checkLoginStatus() {
    const loginToken = getLoginToken();
    const bearer = "Bearer " + getAuthToken();

    try {
      const response = await fetch("/api/userlogin/CheckLoginStatus/" + loginToken, {
        method: "GET",
        headers: {
          Authorization: bearer,
          "Content-Type": "application/json"
        }
      });

      if (!response.ok)
        window.location.href = "/login";
    } catch (e) {
      console.error(e);
    }
  }

  editItem = (id) => {
    if (id > 0) {
      const data = this.state.editData.find((item) => { return item.Id == id });
      this.setState({ editModal: true, Id: data.Id, UserId: data.UserId, DateSent: data.DateSent, ReasonId: data.ReasonId, Reason: data.Reason, Firstname: data.Firstname, Surname: data.Surname, Email: data.Email, Mobile: data.Mobile, IPAddress: data.IPAddress, Message: data.Message, });
    } else
      toastNotification("View Message", "There was an error opening message. Please try again!", false);
  };

  deleteItem = async (id) => {
    const confirmStyles = {
      classNames: {
        confirmButton: 'btn btn-sm confirm-button',
        cancelButton: 'btn btn-sm cancel-button',
      }
    }
    const result = await confirm("Are you sure you want to delete this item?", confirmStyles);
    if (result) {
      this.deleteData(id);
    }
  }

  onGridCommand = (args) => {
    switch (args.commandColumn.type) {
      case 'View':
        this.editItem(args.rowData.Id);
        break;
      case 'Delete':
        this.deleteItem(args.rowData.Id);
        break;
    }
  }

  renderStatus = (props) => {

    switch (props.Status) {

      case 1:
        return "Resolved"
      default:
        return "Pending"
    }

  }



  static renderDataTable(data, gridCommands, gridToolbar, commandClick, renderStatus) {
    return (
      <GridComponent dataSource={data} ref={g => this.grid = g} commandClick={commandClick} allowSorting={true} toolbar={gridToolbar} >
        <ColumnsDirective>
          <ColumnDirective field='Firstname' width='80' headerText="First name" />
          <ColumnDirective field='Surname' width='80' headerText="Last name" />
          <ColumnDirective field='Reason' width='60' headerText="Reason" />
          <ColumnDirective field='Email' width='100' headerText="Email" />
          <ColumnDirective field='Mobile' width='60' headerText="Contact #" />
          <ColumnDirective field='DateSent' width='60' headerText="Date Sent" type="datetime" format="yyyy-MM-dd HH:mm" />
          {/*<ColumnDirective field='Status' width='60' headerText="Status" template={renderStatus} />*/}
          <ColumnDirective headerText='Actions' width='60' commands={gridCommands} />
        </ColumnsDirective>
        <Inject services={[Sort, Edit, CommandColumn, Toolbar]} />
      </GridComponent>
    );
  }

  render() {
    let contents = this.state.loading ? <p className='text-center'><i className='fas fa-spinner fa-spin me-2'></i>Loading...</p> : ContactMessages.renderDataTable(this.state.editData, this.state.gridCommands, this.state.gridToolbar, this.onGridCommand, this.renderStatus);

    return (
      <>
        <div className="container">
          <Row>
            <Col xs={12}>
              <h1>Contact Messages</h1>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              {contents}
            </Col>
          </Row>
        </div>

        <Modal isOpen={this.state.editModal} toggle={this.toggle} className={this.props.className} scrollable size="lg" backdrop="static">
          <ModalHeader toggle={this.toggle} close={<button className="close" onClick={this.toggle}><i className="fas fa-times"></i></button>}>Edit Contact Messages</ModalHeader>
          <ModalBody>
            <b>Name: </b> {this.state.Firstname} {this.state.Surname}<br />
            <b>Reason: </b> {this.state.Reason}<br />
            {/*<b>Status: </b> {this.state.Status}<br />*/}
            <b>Email: </b> {this.state.Email}<br />
            <b>Mobile: </b> {this.state.Mobile}<br />
            {console.log(typeof this.state.DateSent)}
            <b>Date Sent: </b> {this.state.DateSent && this.state.DateSent.toSystemDateTime()}<br />
            {/*<b>Date Attended: </b> {this.state.DateSent}<br />*/}
            {/*<b>IP Address: </b> {this.state.IPAddress}<br />*/}
            <b>Message</b> <br />{this.state.Message}





          </ModalBody>
          <ModalFooter>
            <Button color="dark" size="sm" onClick={this.toggle}>Cancel <i className="far fa-times-circle ms-2"></i></Button>
            {/*<Button color="success" size="sm" onClick={this.saveItem}>Save <i className="far fa-check-circle ms-2"></i></Button>*/}
          </ModalFooter>
        </Modal>
      </>

    );
  }


  async getMessages(bearer, reasonData) {
    try {
      const response = await fetch('api/contactmessages', {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();

        for (var i in data) {
          var reason = this.state.reasonData.find(p => p.Id === data[i].ReasonId);
          if (reason)
            data[i].Reason = reason.Title;
        }

        this.setState({ editData: data, loading: false });
      }
      else {
        console.error(response.status + ": " + response.statusText);
        if (response.status === 401)
          this.props.history.push("/login");
      }

    } catch (e) {
      console.error(e);
    }
  }

  async loadData() {
    console.clear();
    var bearer = 'Bearer ' + getAuthToken();
    try {
      const response = await fetch('api/contactreasons', {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        this.setState({ reasonData: data });
        //console.log(data);
        this.getMessages(bearer, data);
      }
      else {
        console.error(response.status + ": " + response.statusText);
        if (response.status === 401)
          this.props.history.push("/login");
      }

    } catch (e) {
      console.error(e);
    }



  }

  async deleteData(dataId) {
    this.setState({ loading: true });

    var bearer = 'Bearer ' + getAuthToken();
    try {
      const response = await fetch('api/contactmessages/' + dataId, {
        method: 'DELETE',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        await response.json();
        this.loadData();
        ToastUtility.show({
          title: 'Contact Messages', content: 'The contact messages was successfully deleted!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-success'
        });
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          this.props.history.push("/login");
      }
    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
      ToastUtility.show({
        title: 'Contact Messages', content: 'There was an error deleting the contact messages!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-danger'
      });
    }
  }
}

