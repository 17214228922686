import { useEffect, useState } from "react";

const PrivacyPolicy = () => {

  const [policy, setPolicy] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    loadData();
  }, [loading])

  const loadData = async () => {
    try {
      const response = await fetch("api/pagecontents/GetContentByPage/privacy", {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (response.ok) {
        const data = await response.json();
        setPolicy(data);
        setLoading(false);
      } else {
        console.log(response.status + ": " + response.statusText);
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
    }
  }

  const RenderPolicy = () => {
    return (
      <div dangerouslySetInnerHTML={{ __html: policy[0].Copy }}></div>
    )
  }

  const content = loading ? <p className='text-center'><i className='fas fa-spinner fa-spin me-2'></i>Loading...</p> : RenderPolicy();

  return (
    <div>
      <div className="container pb-4">
        {content}
      </div>
    </div>
  );
};

export default PrivacyPolicy;
