import React, { Component} from 'react'
import { getCurrentDomain } from '../../helpers/lookup';

export default class ImageEditor extends Component {

    static parentObj = null;
    constructor(props) {
        super(props);
        ImageEditor.parentObj = this;
        this.state = {
            imgSrc: "",
            imgChanged: false,
            loading: true
        }
    }

    componentDidMount() {
        this.getDefaultImage(this.props.ExistingImage);
    }

    // componentDidUpdate(prevProps) {
    //     // if (this.props.ExistingImage !== prevProps.ExistingImage) {
    //     //     this.setState({ imgChanged: false }, () => this.getDefaultImage());
    //     // }
    // }

    static UpdateImage(imgSrc) {
        if (ImageEditor.parentObj)
            ImageEditor.parentObj.getDefaultImage(imgSrc)
    }

    getDefaultImage = (ExistingImage) => {
        let imgSrc = "https://via.placeholder.com/700x380?text=No+Image";
        if (ExistingImage === "")
            this.setState({ imgSrc, loading: false });
        else {
            if (ExistingImage.startsWith("DynamicData"))
                imgSrc = getCurrentDomain() + ExistingImage;
            else
                imgSrc = ExistingImage;

            this.setState({ imgSrc, loading: false });
        }
    }

    OpenFileDialog = () => {
        let input = document.createElement("input");
        input.type = "file";
        input.accept = "image/*";

        input.onchange = e => {
            var file = e.target.files[0];
            if (file) {
                const reader = new FileReader();

                reader.onloadend = (readerData) => {
                    var Filename = file.name;
                    var FileData = readerData.target.result.split(",")[1];
                    this.setState({ imgSrc: readerData.target.result, imgChanged: true });
                    if (this.props.OnChangeMethod)
                        this.props.OnChangeMethod(Filename, FileData)

                }

                reader.readAsDataURL(file);
            }
        }

        input.click();
    }

    RemoveFile = () => {
        this.setState({ imgSrc: "https://via.placeholder.com//700x380?text=No+Image", imgChanged: false });
        if (this.props.OnChangeMethod)
            this.props.OnChangeMethod("", "");
    }

    getPreviewStyle = () => {
        let styleObj = {};
        if (this.props.FullWidth){
            styleObj.width = "100%";
        }
        else
            styleObj.maxWidth = this.props.Size + "px";
        if (this.props.bgColor)
            styleObj.backgroundColor = this.props.bgColor
        return styleObj;
    }

    render() {
        return (

            this.state.loading ?
                <>Loading Image Editor</> :
                <div className='w-100'>
                    <label>{this.props.LabelText}</label><br />
                    <img src={this.state.imgSrc} className='img-fluid img-thumbnail w-100 mb-2' alt="Editor" style={this.getPreviewStyle()} onClick={this.OpenFileDialog} /><b />
                    <br />
                    <input type="button" className='btn btn-outline-primary btn-sm' value={this.state.imgChanged ? "Change Image" : "Choose Image"} onClick={this.OpenFileDialog} />
                    <input type='button' className='btn btn-outline-danger btn-sm ms-3' value={"Remove Image"} hidden={!this.state.imgChanged} onClick={this.RemoveFile} />
                </div>
        )
    }
}
