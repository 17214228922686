import { Toolbar } from '@syncfusion/ej2-react-grids';

import { TextBoxComponent } from '@syncfusion/ej2-react-inputs'
import React, { Component } from 'react'
import { Button, Col, Container, Row } from 'reactstrap'
import { HtmlEditor, Image, Inject, Toolbar as rteToolbar, QuickToolbar, RichTextEditorComponent } from '@syncfusion/ej2-react-richtexteditor';
import { fieldRequired } from '../../helpers/validation';
import { getAuthToken, getLoginToken, getUserDetails } from '../../helpers/authentication';
import { toastNotification } from '../../helpers/toastHelpers';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { confirm } from 'react-confirm-box';
import ImageEditor from './ImageEditor';


export default class QmsAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      AddedBy: 0,
      DateAdded: new Date(),
      Description: '',
      Filename: '',
      Id: 0,
      Loading: true,
      QmsList: [],
      StoredFile: '',
      Thumbnail: '',
      ThumbnailName: '',
      Title: '',
      FileData: '',
      ThumbnailData: '',
      ExistingFile: ''
    }

    this.handleFileChange = this.handleFileChange.bind(this)
    this.handlePictureChange = this.handlePictureChange.bind(this);
    this.updateState = this.updateState.bind(this)
  }

  componentDidMount() {
    this.checkLoginStatus();
    this.getQMS(0)
  }

  async checkLoginStatus() {
    const loginToken = getLoginToken();
    const bearer = "Bearer " + getAuthToken();

    try {
      const response = await fetch("/api/userlogin/CheckLoginStatus/" + loginToken, {
        method: "GET",
        headers: {
          Authorization: bearer,
          "Content-Type": "application/json"
        }
      });

      if (!response.ok)
        window.location.href = "/login";
    } catch (e) {
      console.error(e);
    }
  }

  updateState(data) {
    this.setState(data)
  }

  handleFileChange(e, type) {
    let file = e.target.files[0];
    let Filename = this.state.Filename;
    let FileData = this.state.FileData;

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        Filename = file.name;
        FileData = reader.result.split(",")[1];
        this.updateState({
          Filename, FileData
        });
      };

      reader.readAsDataURL(file);
    }
  }

  handlePictureChange(ThumbnailName, ThumbnailData) {
    this.setState({ ThumbnailData, ThumbnailName });
  }

  async getQMS(Id) {
    try {
      var bearer = 'Bearer ' + getAuthToken();

      const response = await fetch('/api/qms', {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        },
      });

      if (response.ok) {
        const body = await response.json()
        const QmsList = [{ Id: 0, Title: "New QMS" }, ...body];
        if (Id > 0) {
          const selectedQMS = body.find(c => c.Id === Id);
          this.setState({ QmsList, ...selectedQMS, Id: selectedQMS.Id, ExistingFile: selectedQMS.Filename, FileData: "" })

        } else
          this.setState({ QmsList, ExistingFile: "", FileData: "" })
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          this.props.history.push("/login");
      }
    } catch (error) {
      toastNotification('QMS', 'There was an error loading the qms!', false);
    }
  }

  render() {
    return (
      <Container>
        <Row>
          <Col className='mb-3'>
            <label>Select QMS</label>
            <DropDownListComponent
              dataSource={this.state.QmsList}
              fields={{ text: "Title", value: "Id" }}
              onChange={(e) => {
                let Id = e.value
                let selectedQms = this.state.QmsList.find(item => item.Id === Id);
                if (selectedQms.Id > 0)
                  this.setState({ ...selectedQms, FileData: '', ThumbnailData: '', ExistingFile: selectedQms.Filename }, () => ImageEditor.UpdateImage(this.state.Thumbnail))
                else
                  this.setState({ Id: 0, Title: "", Description: "", Filename: "", StoredFile: "", Thumbnail: "", ThumbnailName: "", FileData: '', ThumbnailData: '', ExistingFile: '' }, () => ImageEditor.UpdateImage(this.state.Thumbnail))
              }}
              value={this.state.Id}
            />
            <Col md={12}>
              <label className='d-flex'>
                <span>Title</span>
                <div id="errTitle" className='ErrorText ps-2' />
              </label>
              <TextBoxComponent value={this.state.Title} onChange={e => this.setState({ Title: e.target.value })} />
            </Col>
          </Col>
        </Row>

        <Row className='my-2'>

          <Col>
            <label>Description</label>
            <RichTextEditorComponent toolbarSettings={this.fullToolbarSettings} insertImageSettings={this.imageSettings} height={200} value={this.state.Description} change={e => this.setState({ Description: e.value })}>
              <Inject services={[rteToolbar, Toolbar, HtmlEditor, Image, QuickToolbar]} />
            </RichTextEditorComponent>
          </Col>
        </Row>

        <Row className='my-2'>

          <Col md={12} className='mb-3'>
            <label className='d-flex'>
              <span>File </span>
              <div id="errFile" className='ErrorText ps-2' />
            </label>

            <input
              type='file'
              id="fileInput"
              className='form-control form-control-file'
              onChange={(e) => this.handleFileChange(e, "file")}
            />
            {this.state.ExistingFile ? <div className='d-flex'>
              <div className='text-success fw-500'>EXISTING:</div>&nbsp;
              {this.state.ExistingFile}
            </div> : ""}
          </Col>
          <Col>
            <ImageEditor LabelText={"Thumbnail"} ExistingImage={this.state.Thumbnail} Size={"350"} OnChangeMethod={this.handlePictureChange} />
          </Col>
        </Row>
        <div className="text-end">
          <Button color={"danger"} size='sm' className='me-2' onClick={() => this.deleteItem(this.state.Id)} hidden={this.state.Id === 0}>Delete</Button>
          <Button color={"success"} size='sm' onClick={this.saveItem}>Save</Button>
        </div>
      </Container>
    )
  }

  deleteItem = async (id) => {
    const confirmStyles = {
      classNames: {
        confirmButton: 'btn btn-sm confirm-button btn-danger',
        cancelButton: 'btn btn-sm cancel-button btn-success',
      }
    }
    const result = await confirm("Are you sure you want to delete this qms?", confirmStyles);
    if (result) {
      this.deleteData(id);
    }
  }

  saveItem = async (event) => {
    event.stopPropagation();
    var valid = true;

    valid &= fieldRequired(this.state.Title, "errTitle", "* Required");

    const FileData = this.state.FileData || this.state.StoredFile;
    valid &= fieldRequired(FileData, "errFile", "* Required");

    if (valid) {
      this.saveData(this.state.Id);
    }
  }


  async deleteData(id) {
    try {
      var bearer = 'Bearer ' + getAuthToken();

      const response = await fetch('/api/qms/' + id, {
        method: 'DELETE',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        },
      });
      if (response.ok) {
        toastNotification('QMS', 'The Qms was successfully deleted!', true);
        this.setState({
          Description: '',
          Filename: '',
          Id: 0,
          StoredFile: '',
          Thumbnail: '',
          ThumbnailName: '',
          Title: '',
          ExistingFile: '',
          FileData: ''
        }, () => ImageEditor.UpdateImage(this.state.Thumbnail))
        this.getQMS()
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          this.props.history.push("/login");
      }

    } catch (e) {
      console.error(e);
      this.setState({ Loading: false });
      toastNotification('QMS', 'There was an error deleting the qms!', false);
    }
  }

  async saveData() {
    this.setState({ Loading: true });
    var bearer = 'Bearer ' + getAuthToken();

    var data = {

      Description: this.state.Description,
      FileName: this.state.Filename,
      Id: this.state.Id,
      StoredFile: this.state.StoredFile,
      Title: this.state.Title,
      Thumbnail: this.state.Thumbnail,
      ThumbnailName: this.state.ThumbnailName,
      FileData: this.state.FileData,
      ThumbnailData: this.state.ThumbnailData
    };

    try {
      const response = await fetch('/api/qms', {
        method: 'POST',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data),
      });
      if (response.ok) {
        const id = await response.json();
        // this.setState({ Id: id })
        toastNotification('QMS', 'The Qms was successfully saved!', true);
        this.getQMS(id);
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          this.props.history.push("/login");
      }

    } catch (e) {
      console.error(e);
      this.setState({ Loading: false });
      toastNotification('QMS', 'There was an error saving the qms!', false);
    }
  }
}
