import React from "react";
import { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import Article from "../Article";
import { loadData } from "../GetData";

const HomeMedia = () => {
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getArticles = async () => {
      const articlesFromServer = await loadData('newsarticles/GetArticles');
      setArticles(articlesFromServer);
      setLoading(false);
    };

    getArticles();
  }, []);

  const RenderArticles = () => {
    var viewableArticles = [];
    if (articles) {
      viewableArticles = articles.slice(0, 3);
    }

    return (
      <div className="container my-5 pb-3" >
        <h1>Media</h1>
        {viewableArticles.length > 0 ?
          <Row className="articleRow">
            {viewableArticles.map((article) => (
              <Col md={4} key={article.Id}>
              {console.log(article)}
                <Article
                  articleID={article.Id}
                  articleSlug={article.Slug}
                  title={article.Title}
                  subtitle="Social Media Post"
                  thumbnail={article.Thumbnail}
                />
              </Col>
            ))}
          </Row>
          :
          <p className="">There are currently no news articles available. Please check later!</p>
        }
      </div>
    )
  }

  const content = loading ? <p><i className='fas fa-spinner fa-spin me-2'></i>Loading...</p> : RenderArticles();

  return (
    <div className="my-5">
      {content}
    </div>
  );
};

export default HomeMedia;
