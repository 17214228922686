import React, { Component } from 'react';

export class AdminFooter extends Component {

  static copyrightDate = "";

  constructor(props) {
    super(props);
    this.copyrightDate = new Date().getFullYear();


  }

  // componentDidMount() {
  //   this.checkLoginStatus();
  // }


  // async checkLoginStatus() {
  //   const loginToken = getLoginToken();
  //   const bearer = "Bearer " + getAuthToken();
  //   try {
  //     const response = await fetch("/api/userlogin/CheckLoginStatus/" + loginToken, {
  //       method: "GET",
  //       headers: {
  //         Authorization: bearer,
  //         "Content-Type": "application/json"
  //       }
  //     });

  //     if (!response.ok)
  //       window.location.href = "/login";
  //   } catch (e) {
  //     console.error(e);
  //   }
  // }

  render() {
    return (
      <>
        <footer className="mt-5">
          <div className="container">

            <div className="row">
              <div className="col-12 copyright mt-0">
                Copyright&copy; {this.copyrightDate} HarnessPro.co.za. All Rights Reserved.
              </div>
            </div>
          </div>
        </footer>
      </>
    );
  }
}
