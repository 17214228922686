import { getAuthToken } from "../helpers/authentication";
import { sortArticles } from "../helpers/articleHelpers";

export async function loadData(apiLocation) {
  try {
    const response = await fetch(`api/${apiLocation}`, {
      method: "GET",
      withCredentials: true,
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (response.ok) {
      const data = await response.json();

      switch(apiLocation) {
        case 'newsarticles/GetArticles':
          return sortArticles(data);
          
        case 'contactreasons/GetContactReasons':
          return data;

        default:
          break;
      }
    } else {
      console.log(`${response.status}: ${response.statusText}`);
    }
  } catch (error) {
    console.error(error);
  }
};

export async function loadArticle(id) {
  let bearer = `Bearer ${getAuthToken()}`;
  try {
    const response = await fetch(`api/newsarticles/GetBySlug/${id}`, {
      method: "GET",
      withCredentials: true,
      credentials: "include",
      headers: {
        Authorization: bearer,
        "Content-Type": "application/json",
      },
    });

    if (response.ok) {
      const article = await response.json();
      return article;
    } else {
      console.log(`${response.status}: ${response.statusText}`);
      if (response.status === 401) this.props.history.push("/login");
    }
  } catch (error) {
    console.error(error);
  }
}

