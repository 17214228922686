
import React, { Component } from 'react';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { ToastUtility } from '@syncfusion/ej2-react-notifications';
import { Button } from 'reactstrap';
import { setAuthToken, setLoginToken, setUserDetails, setUserLevel } from '../helpers/authentication';

//import { getAuthToken, setAuthToken } from '../helpers/authentication';
import '../helpers/string-prototypes';

export class Login extends Component {

  constructor(props) {
    super(props);
    //this.state = { forecasts: [], loading: true };
    this.state = {
      loading: false,
      Username: '',
      Password: '',
    };
    // setAuthToken("52aefd71-7eb3-4d78-be0e-d8fe4313681b", new Date());
  }

  loginUser = async (event) => {
    this.setState({ loading: true });
    //console.log("attempting login")
    var params = "?email=" + this.state.Username + "&password=" + this.state.Password;
    //console.log(params)
    try {
      const response = await fetch('/api/userlogin/' + params);

      if (response.ok) {
        const data = await response.json();
        this.setState({ loading: false });
        setAuthToken(data.Item1, new Date());
        setLoginToken(data.Item2)
        document.location.href = "/admin";
      }
      else {
        this.setState({ loading: false });
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401 || response.status === 404) {
          ToastUtility.show({
            title: 'Login Failed', content: 'Either your username or password is incorrect!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-danger'
          });
        } else if (response.status === 403)
          ToastUtility.show({
            title: 'Login Failed', content: 'Your account has been locked out!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-info'
          });
      }
    } catch (e) {
      console.error(e);
    }
  }


  render() {
    return (
      <div className="container">

        <div className='row mb-5 justify-content-center'>

          <div className='col-md-4 login-background mx-auto justify-self-center'>
            <div className='login-card'>
              <h2>Login</h2>
              <p>Please enter your credentials below</p>
              <div className='mb-1'>
                <TextBoxComponent id='tbUsername' name='tbUsername' placeholder='Username / Email Address' type='text' maxLength='150' floatLabelType='Auto' showClearButton={true} onChange={e => this.setState({ Username: e.target.value })} /> <div id='tbUsernameError' className='error-message' />
              </div>
              <div className='mb-4'>
                <TextBoxComponent id='tbPassword' name='tbPassword' placeholder='Password' type='password' maxLength='150' floatLabelType='Auto' showClearButton={true} onChange={e => this.setState({ Password: e.target.value })} /> <div id='tbNameError' className='error-message' />
              </div>
              <div className='text-end'>
                <Button color="dark" size="sm" onClick={this.loginUser}>Login <i className="far fa-chevron-right ms-2"></i></Button><br />

              </div>
              <div className='text-start'>
                {/*<a role='button' onClick={() => this.clickForgottenPassword()}>Forgot Password?</a>*/}
              </div>
            </div>
          </div>

          <div className='col-md-8 '>
            <div className="text-center">
              {/*<img src="./img/global/cars-logo_transparent.png" alt="Team Business Cars" className="img-fluid" />*/}
            </div>
          </div>
        </div>


        {/*<h1>Hello, world!</h1>*/}
        {/*<p>Welcome to your new single-page application, built with:</p>*/}
        {/*<ul>*/}
        {/*  <li><a href='https://get.asp.net/'>ASP.NET Core</a> and <a href='https://msdn.microsoft.com/en-us/library/67ef8sbd.aspx'>C#</a> for cross-platform server-side code</li>*/}
        {/*  <li><a href='https://facebook.github.io/react/'>React</a> for client-side code</li>*/}
        {/*  <li><a href='http://getbootstrap.com/'>Bootstrap</a> for layout and styling</li>*/}
        {/*</ul>*/}
        {/*<p>To help you get started, we have also set up:</p>*/}
        {/*<ul>*/}
        {/*  <li><strong>Client-side navigation</strong>. For example, click <em>Counter</em> then <em>Back</em> to return here.</li>*/}
        {/*  <li><strong>Development server integration</strong>. In development mode, the development server from <code>create-react-app</code> runs in the background automatically, so your client-side resources are dynamically built on demand and the page refreshes when you modify any file.</li>*/}
        {/*  <li><strong>Efficient production builds</strong>. In production mode, development-time features are disabled, and your <code>dotnet publish</code> configuration produces minified, efficiently bundled JavaScript files.</li>*/}
        {/*</ul>*/}
        {/*<p>The <code>ClientApp</code> subdirectory is a standard React application based on the <code>create-react-app</code> template. If you open a command prompt in that directory, you can run <code>npm</code> commands such as <code>npm test</code> or <code>npm install</code>.</p>*/}
        {/*<p>Current Token: {getAuthToken().fulltrim()}</p>*/}
      </div>
    );
  }
}
