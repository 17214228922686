import { Row, Col } from "reactstrap";
import { getAuthToken } from "../../helpers/authentication";
import { toastNotification } from "../../helpers/toastHelpers";
import React, { useEffect, useState } from "react";
import IndustryBlock from "./IndustryBlock";

const HomeIndustries = () => {
  const [industries, setIndustries] = useState([])
  const [loading, setLoading] = useState(true)

  async function loadData() {
    try {
      var bearer = 'Bearer ' + getAuthToken();

      const response = await fetch('/api/industries', {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        },
      });

      if (response.ok) {
        const body = await response.json()
        setIndustries(body)
        var statuses = [];
        for (var i = 0; i < body.length; i++) {
          statuses.push({ image: body[i].InactiveImage });
        }
        setLoading(false);
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          this.props.history.push("/login");
      }
    } catch (error) {
      toastNotification('Industries', 'There was an error loading the Industries!', false);
    }
  }

  useEffect(() => {
    loadData()
  }, [])

  const RenderIndustries = () => {
    return (<Row className="mt-3 ">
      {industries.map((item, index) => {
        return (
          <Col md={4} className="industryCol mb-4" key={index} >
            <IndustryBlock Index={index} Title={item.Title} ActiveImage={item.ActiveImage} InactiveImage={item.InactiveImage} />
          </Col>)
      })}
    </Row>);
  }

  const content = loading ? <></> : RenderIndustries();

  return (
    <div className="container-fluid industriesContain position-relative py-5">
      <div className="background-image py-5">
        <div className="py-3 ">
          <div className="container">
            <h1 className="mb-3">Industries</h1>
            <h5 className="mb-5 text-white">We manufacture, design and reverse engineer harnesses to customer-specific requirements.</h5>
            {content}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeIndustries;
