import React, { Component } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink, UncontrolledDropdown,  DropdownItem, DropdownToggle, DropdownMenu } from 'reactstrap';
import { Link } from 'react-router-dom';
import { clearUserDetails, getUserDetails } from "../../helpers/authentication";


export class AdminNavMenu extends Component {

    constructor(props) {
        super(props);

        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.state = {
            collapsed: true
        };
    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    logOut() {
        //console.log("Bye now!");
        clearUserDetails();
        //return false
    }

    render() {
        return (
            <>
                <header>
                    <Navbar className="navbar-expand-lg ng-white mb-3" light>
                        <Container>
                            <NavbarBrand tag={Link} to="/admin">
                                <img src="/img/global/harnesspro-logo.png" className="img-fluid" alt="HarnessPro Logo" />
                            </NavbarBrand>
                            <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
                            <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>
                                <ul className="navbar-nav flex-grow">
                                    <NavItem className="d-none">
                                        <NavLink tag={Link} className="text-dark" to="/admin/banners">Banners</NavLink>
                                    </NavItem>
                                    <UncontrolledDropdown inNavbar nav>
                                        <DropdownToggle caret nav>
                                            Contents
                                        </DropdownToggle>
                                        <DropdownMenu right>
                                        <DropdownItem>
                                                <NavLink tag={Link} className="text-dark" to="/admin/banners">
                                                    Banners
                                                </NavLink>
                                            </DropdownItem>
                                            <DropdownItem>
                                                <NavLink tag={Link} className="text-dark" to="/admin/page-contents">
                                                    Page Content
                                                </NavLink>
                                            </DropdownItem>
                                            <DropdownItem>
                                                <NavLink tag={Link} className="text-dark" to="/admin/qms">
                                                    QMS
                                                </NavLink>
                                            </DropdownItem>
                                            <DropdownItem>
                                                <NavLink tag={Link} className="text-dark" to="/admin/innovations">
                                                    Innovations
                                                </NavLink>
                                            </DropdownItem>
                                            <DropdownItem>
                                                <NavLink tag={Link} className="text-dark" to="/admin/industries">
                                                    Industries
                                                </NavLink>
                                            </DropdownItem>
                                            <DropdownItem>
                                                <NavLink tag={Link} className="text-dark" to="/admin/technologies">
                                                    Technology Slides
                                                </NavLink>
                                            </DropdownItem>
                                            <DropdownItem>
                                                <NavLink tag={Link} className="text-dark" to="/admin/quality-reports">
                                                   Quality Reports
                                                </NavLink>
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                    <NavItem>
                                        <NavLink tag={Link} className="text-dark" to="/admin/articles">Articles</NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink tag={Link} className="text-dark" to="/admin/contactmessages">Contact Messages</NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink tag={Link} className="text-dark" to="/admin/contactreasons">Contact Reasons</NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink tag={Link} className="text-dark" to="/admin/users">Admin Users</NavLink>
                                    </NavItem>

                                    <NavItem>
                                        <NavLink tag={Link} className="text-dark" onClick={this.logOut} to="/login" >Logout</NavLink>
                                    </NavItem>
                                </ul>
                            </Collapse>
                        </Container>
                    </Navbar>
                </header>
                <hr />
            </>
        );
    }
}
