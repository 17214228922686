import React, { Component } from 'react';
import { Button, Modal, ModalFooter, ModalHeader, ModalBody, Row, Col } from 'reactstrap';
import { ColumnDirective, ColumnsDirective, CommandColumn, GridComponent, Sort, Edit, Toolbar } from '@syncfusion/ej2-react-grids';
import { ListBoxComponent } from '@syncfusion/ej2-react-dropdowns';
import { ToastUtility } from '@syncfusion/ej2-react-notifications';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { Inject } from '@syncfusion/ej2-react-richtexteditor';
import { confirm } from "react-confirm-box";
import { getAuthToken, getLoginToken } from '../../helpers/authentication';
import ImageEditor from './ImageEditor';
import { toastNotification } from '../../helpers/toastHelpers';

export class Industries extends Component {

    constructor(props) {
        super(props);
        const commandTemplate = [
            { type: 'Edit', buttonOption: { cssClass: 'e-flat', iconCss: 'e-edit e-icons' } },
            { type: 'Delete', buttonOption: { cssClass: 'e-flat', iconCss: 'e-delete e-icons' } }
        ];

        this.state = {
            editId: 0,
            editData: [], orderData: [], loading: true, editModal: false, orderModal: false, gridCommands: commandTemplate, gridToolbar: ['Search'],
            Id: 0,
            Title: '',
            slug: '',
            InactiveImage: '',
            InactiveImageName: '',
            ActiveImage: '',
            ActiveImageName: '',
            MainImage: '',
            MainImageName: '',
            MainImageData: '', ActiveImageData: '', InactiveImageData: '',
        };
        this.toggle = this.toggle.bind(this);
        this.onItemDrop = this.onItemDrop.bind(this);
        this.toggleOrderModal = this.toggleOrderModal.bind(this);
        this.updateState = this.updateState.bind(this)
        this.handleActiveImage = this.handleActiveImage.bind(this);
        this.handleInactiveImage = this.handleInactiveImage.bind(this);
        this.handleMainImage = this.handleMainImage.bind(this);
        this.SaveOrder = this.SaveOrder.bind(this);
    }

    toggle() {
        this.setState({
            editModal: !this.state.editModal
        });
    }


    toggleOrderModal() {
        this.setState({
            orderModal: !this.state.orderModal
        });
    }


    componentDidMount() {
        document.title = "Industries Administration";
        this.checkLoginStatus();
        this.loadData();
    }

    async checkLoginStatus() {
        const loginToken = getLoginToken();
        const bearer = "Bearer " + getAuthToken();
    
        try {
          const response = await fetch("/api/userlogin/CheckLoginStatus/" + loginToken, {
            method: "GET",
            headers: {
              Authorization: bearer,
              "Content-Type": "application/json"
            }
          });
    
          if (!response.ok)
            window.location.href = "/login";
        } catch (e) {
          console.error(e);
        }
      }

    editItem = (id) => {
        if (id > 0) {
            const data = this.state.editData.find((item) => { return item.Id === id });
            this.setState({ Id: data.Id, Title: data.Title, slug: data.slug, ActiveImage: data.ActiveImage, ActiveImageName: data.ActiveImage, InactiveImage: data.InactiveImage, InactiveImageName: data.InactiveImage, MainImage: data.MainImage, MainImageName: data.MainImage, AddedBy: data.AddedBy, DateAdded: data.DateAdded, ModifiedBy: data.ModifiedBy, DateModified: data.DateModified, Status: data.Status, MainImageData: '', ActiveImageData: '', InactiveImageData: '' });
        }
        else {
            //clear state fields
            this.setState({
                Id: 0,
                editId: 0,
                Title: '',
                slug: '',
                ActiveImage: '',
                InactiveImage: '',
                MainImage: '',
                MainImageData: '', ActiveImageData: '', InactiveImageData: ''
            });
        }
        this.setState({ editId: id, editModal: true });
    };

    saveItem = async (event) => {
        event.stopPropagation();
        var valid = true;

        if (valid) {
            // const data = this.state.editData.find((item) => { return item.Id == this.state.editId });
            // if (this.state.editId > 0) { //do not overwrite the following fie when updating
            //     this.state.AddedBy = data.AddedBy;
            //     this.state.DateAdded = data.DateAdded;
            //     this.state.Id = data.Id;
            //     this.state.Status = data.Status;
            // }
            // else {
            //     this.state.Status = 1;
            // }
            this.saveData(this.state.editId);
            this.setState({ editModal: false });
        }
    }

    deleteItem = async (id) => {
        const confirmStyles = {
            classNames: {
                confirmButton: 'btn btn-sm confirm-button',
                cancelButton: 'btn btn-sm cancel-button',
            }
        }
        const result = await confirm("Are you sure you want to delete this item?", confirmStyles);
        if (result) {
            this.deleteData(id);
        }
    }

    onGridCommand = (args) => {
        switch (args.commandColumn.type) {
            case 'Edit':
                this.editItem(args.rowData.Id);
                break;
            case 'Delete':
                this.deleteItem(args.rowData.Id);
                break;
            default:
                break
        }
    }


    static renderDataTable(data, gridCommands, gridToolbar, commandClick) {
        return (
            <GridComponent dataSource={data} ref={g => this.grid = g} commandClick={commandClick} allowSorting={true} toolbar={gridToolbar} >
                <ColumnsDirective>
                    <ColumnDirective field='DisplayOrder' width='40' headerText="Display Order" />
                    <ColumnDirective field='Title' width='100' headerText="Title" />
                    <ColumnDirective headerText='Actions' width='100' commands={gridCommands} />
                </ColumnsDirective>
                <Inject services={[Sort, Edit, CommandColumn, Toolbar]} />
            </GridComponent>
        );
    }

    updateState(data) {
        this.setState({ ...data })
    }

    handleFileChange(e, property, propertyName) {
        let file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                this.updateState({
                    [property]: reader.result.split(",")[1],
                    [propertyName]: file.name
                });
            };

            reader.readAsDataURL(file);
        }
    }

    handleActiveImage(ActiveImageName, ActiveImageData) {
        this.setState({ ActiveImageData, ActiveImageName });
    }

    handleInactiveImage(InactiveImageName, InactiveImageData) {
        this.setState({ InactiveImageName, InactiveImageData });
    }

    handleMainImage(MainImageName, MainImageData) {
        this.setState({ MainImageData, MainImageName })
    }

    onItemDrop(e) {
        const orderData = [];
        e.source.currentData.map((item, idx) =>
            orderData.push({ DisplayOrder: (idx + 1), Id: item.Id, Title: item.Title })
        );
        this.setState({ orderData })
    }

    render() {
        let contents = this.state.loading ? <p className='text-center'><i className='fas fa-spinner fa-spin me-2'></i>Loading...</p> : Industries.renderDataTable(this.state.editData, this.state.gridCommands, this.state.gridToolbar, this.onGridCommand);

        return (
            <>
                <div className="container">
                    <Row>
                        <Col xs={6}>
                            <h1>Industries Administration</h1>
                        </Col>
                        <Col xs={6} className="text-end align-self-center">
                            <Button color='secondary' outline size='sm' onClick={this.toggleOrderModal} className='me-2'>Set Display Order <i className='fas fa-sort ms-2'></i></Button>
                            <Button color="primary" size="sm" data-recordid="0" onClick={() => this.editItem()}>Add New <i className="fas fa-plus-circle ms-2"></i></Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            {contents}
                        </Col>
                    </Row>
                </div>

                <Modal isOpen={this.state.editModal} toggle={this.toggle} className={this.props.className} scrollable size="lg" backdrop="static">
                    <ModalHeader toggle={this.toggle} close={<button className="close" onClick={this.toggle}><i className="fas fa-times"></i></button>}>Edit Industries</ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col className='mb-3'>
                                <TextBoxComponent id='tbTitle' name='tbTitle' placeholder='Title' type='text' maxLength='250' floatLabelType='Auto' showClearButton={true} value={this.state.Title} onChange={e => this.setState({ Title: e.target.value })} /> <div id='tbTitleError' className='error-message' />
                            </Col>
                            {/* <Col className='mb-3'>
                                <TextBoxComponent id='tbslug' name='tbslug' placeholder='slug' type='text' maxLength='100' floatLabelType='Auto' showClearButton={true} value={this.state.slug} onChange={e => this.setState({ slug: e.target.value })} /> <div id='tbslugError' className='error-message' />
                            </Col> */}
                        </Row>
                        <Row>
                            <Col sm={12} className='mb-3'>
                                <ImageEditor LabelText={"Main Image"} FullWidth={true} ExistingImage={this.state.MainImage} OnChangeMethod={this.handleMainImage} />
                            </Col>
                            <Col md={6} className='mb-3'>
                                <ImageEditor LabelText={"Active Image"} Size={"200"} bgColor={"#ccc"} ExistingImage={this.state.ActiveImage} OnChangeMethod={this.handleActiveImage} />
                            </Col>
                            <Col md={6} className='mb-3'>
                                <ImageEditor LabelText={"Inactive Image"} Size={"200"} ExistingImage={this.state.InactiveImage} OnChangeMethod={this.handleInactiveImage} />
                            </Col>
                        </Row>

                    </ModalBody>
                    <ModalFooter>
                        <Button color="dark" size="sm" onClick={this.toggle}>Cancel <i className="far fa-times-circle ms-2"></i></Button>
                        <Button color="success" size="sm" onClick={this.saveItem}>Save <i className="far fa-check-circle ms-2"></i></Button>
                    </ModalFooter>
                </Modal>

                <Modal isOpen={this.state.orderModal} toggle={this.toggleOrderModal} scrollable size='lg' backdrop="static">
                    <ModalHeader toggle={this.toggleOrderModal} close={<button className='btn-close' onClick={this.toggleOrderModal}></button>}>Set Display Order</ModalHeader>

                    <ModalBody>
                        <ListBoxComponent dataSource={this.state.orderData} allowDragAndDrop={true} fields={{ text: "Title" }} drop={this.onItemDrop} />
                    </ModalBody>
                    <ModalFooter>
                        <Button color='dark' size='sm' onClick={this.toggleOrderModal}>Cancel <i className="far fa-times-circle ms-2"></i></Button>
                        <Button color='success' size='sm' onClick={this.SaveOrder}>Save <i className="far fa-check-circle ms-2"></i></Button>
                    </ModalFooter>
                </Modal>
            </>

        );
    }

    async SaveOrder() {
        var bearer = 'Bearer ' + getAuthToken();
        const data = JSON.stringify(this.state.orderData);
        this.setState({ orderModal: false, loading: true })
        try {
            const response = await fetch("api/industries/SaveDisplayOrder", {
                method: "POST",
                withCredentials: true,
                credentials: "include",
                headers: {
                    "Authorization": bearer,
                    "Content-Type": "application/json"
                },
                body: data
            });

            if (response.ok) {
                toastNotification('Display Order', 'Display order was successfully saved!', true);
                this.loadData();
            }

        } catch (e) {
            console.error(e);
        }
    }


    async loadData() {

        var bearer = 'Bearer ' + getAuthToken();
        try {
            const response = await fetch('api/industries', {
                method: 'GET',
                withCredentials: true,
                credentials: 'include',
                headers: {
                    'Authorization': bearer,
                    'Content-Type': 'application/json'
                }
            });
            if (response.ok) {
                const data = await response.json();
                let orderData = [];
                data.sort(function (a, b) { return a.DisplayOrder - b.DisplayOrder }).map((item, idx) => orderData.push({ Id: item.Id, DisplayOrder: item.DisplayOrder, Title: item.Title }));
                this.setState({ editData: data, orderData, loading: false });
            }
            else {
                console.log(response.status + ": " + response.statusText);
                if (response.status === 401)
                    this.props.history.push("/login");
            }

        } catch (e) {
            console.error(e);
        }
    }

    async saveData(dataId) {

        this.setState({ loading: true, showError: false, showSuccess: false });

        var bearer = 'Bearer ' + getAuthToken();
        var data = {
            Id: this.state.Id,
            Title: this.state.Title,
            slug: this.state.slug,
            InactiveImage: this.state.InactiveImage,
            InactiveImageName: this.state.InactiveImageName,
            ActiveImage: this.state.ActiveImage,
            ActiveImageName: this.state.ActiveImageName,
            MainImageName: this.state.MainImageName,
            MainImage: this.state.MainImage,
            MainImageData: this.state.MainImageData,
            ActiveImageData: this.state.ActiveImageData,
            InactiveImageData: this.state.InactiveImageData
        }

        try {
            const response = await fetch('api/industries', {
                method: 'POST',
                withCredentials: true,
                credentials: 'include',
                headers: {
                    'Authorization': bearer,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data),
            });
            if (response.ok) {
                await response.json();
                this.loadData();
                toastNotification('Industries', 'The industries was successfully saved!', true);

            }
            else {
                console.log(response.status + ": " + response.statusText);
                if (response.status === 401)
                    this.props.history.push("/login");

                this.setState({ loading: false });
                toastNotification('Industries', 'There was an error saving the industries!', false);
            }

        } catch (e) {
            console.error(e);
            this.setState({ loading: false });
            toastNotification('Industries', 'There was an error saving the industries!', false);
        }
    }

    async deleteData(dataId) {
        this.setState({ loading: true });

        var bearer = 'Bearer ' + getAuthToken();
        try {
            const response = await fetch('api/industries/' + dataId, {
                method: 'DELETE',
                withCredentials: true,
                credentials: 'include',
                headers: {
                    'Authorization': bearer,
                    'Content-Type': 'application/json'
                }
            });
            if (response.ok) {
                await response.json();
                this.loadData();
                ToastUtility.show({
                    title: 'Industries', content: 'The industries was successfully deleted!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-success'
                });
                toastNotification('Industries','The industries was successfully deleted!', true);
            }
            else {
                console.log(response.status + ": " + response.statusText);
                if (response.status === 401)
                    this.props.history.push("/login");
            }
        } catch (e) {
            console.error(e);
            this.setState({ loading: false });
            toastNotification('Industries', 'There was an error deleting the industries!', false);
        }
    }
}

