export const fieldRequired = (data, errorContainer, errorMessage) => {
  if (!data || data === '' || data == 0) {
    document.getElementById(errorContainer).innerText = errorMessage;
    document.getElementById(errorContainer).focus();
    return false;
  }
  else {
    document.getElementById(errorContainer).innerText = "";
    return true;
  }
};