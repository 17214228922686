/* eslint-disable array-callback-return */
import React, { Component } from "react";
import { getAuthToken } from "../helpers/authentication";
import { toastNotification } from "../helpers/toastHelpers";
import { getCurrentDomain } from "../helpers/lookup";


export class Industries extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imName: "",
      activePath: "",
      menuItems: []
    };
    this.getImage = this.getImage.bind(this)
  }

  currentLocation = (Name) => {
    var location = window.location.pathname;
    return location.includes(Name);
  };

  componentDidMount() {
    this.loadData()
  }

  getImage() {
    let imagePath = ""
    this.state.menuItems.map(item => {
      if (this.currentLocation(item.Title.replaceAll(" ", ""))) {
        imagePath = getCurrentDomain() + item.MainImage
      }
    })
    return imagePath.replace(/\\/g, "/");
  }

  render() {
    //todo: check the location URL. Based on the URL set link state to active and set the main image
    //todo: the onclick event simply sets the location - rather just use an href so you dont need to do any JS.
    //const currentImage = this.imstyle;
    //const currentImage = this.state.imStyle;

    function setStyling(type, activePic, inactivePic) {
      activePic = activePic.replaceAll("\\", "/");
      inactivePic = inactivePic.replaceAll("\\", "/")
      if (type === "inactive") {
        return {
          background: `url(${getCurrentDomain() + inactivePic}) no-repeat center left`,
          // backgroundColor: "#ececec",
          backgroundSize: "contain"
        }
      } else {
        return {
          background: `url(${getCurrentDomain() + activePic}) no-repeat center left`,
          // backgroundColor: "#9cb916",
          backgroundSize: "contain",
          color: "#fff"
        }
      }
    }


    return (
      <>
        <div className="industries container py-1">
          <div className="row g-0 my-5">
            <div className="col-md-5 left-panel p-5 py-4 mb-0" style={{ maxHeight: "750px" }}>
              <h1>Industries</h1>
              <p className="pb-3 green-text">
                Harnesses are extensively used in all vehicles, such as general
                transport, mining, manufacturing, forestry, and appliances such
                as stoves, washing machines and the like. At HarnessPro we
                specialise in manufacturing complete harnesses for the following
                applications:
              </p>
              <div>
                {this.state.menuItems.map((item, index) => {
                  let status = this.currentLocation(item.Title.replaceAll(" ", "")) ? "active" : "inactive";
                  return (<React.Fragment key={index}>
                    <a
                      href={`industries/${item.Title.replaceAll(" ", "")}`}
                      className={`my-2 py-1 icon-link mining  ${this.currentLocation(item.Title.replaceAll(" ", "")) ? "active" : ""}`}
                      style={setStyling(status, item.ActiveImage, item.InactiveImage)}
                    >
                      {item.Title}
                    </a>
                    <img
                      src={getCurrentDomain() + item.MainImage}
                      className="img-fluid d-md-none w-100 mb-3"
                      alt="Mining"
                    /></React.Fragment>)
                })}
              </div>
            </div>

            <div className="col-md-7 h-100 right-panel">
              {/* // id={item.Title.replaceAll(" ", "")}
            // > */}
              <div autoFocus={true} style={{ backgroundImage: `url("${this.getImage()}")`, backgroundPosition: "center center", backgroundSize: "cover", minHeight: "750px", minWidth: "100%" }}></div>
              {/* <img
                src={this.getImage()}
                className="img-fluid d-none d-md-inline w-100"
                alt={this.state.imName.split('/')[0]}
              /> */}
            </div>
          </div>
        </div>
      </>
    );
  }

  async loadData() {
    try {
      var bearer = 'Bearer ' + getAuthToken();
      const response = await fetch('/api/industries', {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        },
      });

      if (response.ok) {
        const body = await response.json()
        let imName = "";
        for (let i = 0; i < body.length; i++) {
          if (this.currentLocation() === `/industries/${body[i].Title}`) {
            imName = getCurrentDomain() + body[i].MainImage;
          }
        }
        this.setState({ menuItems: body, imName })
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          this.props.history.push("/login");
      }
    } catch (error) {
      console.log(error);
      toastNotification('Industries', 'There was an error loading the Industries!', false);
    }
  }
}
