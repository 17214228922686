import React, { Component } from "react";
import {
  Button,
  Modal,
  ModalFooter,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
} from "reactstrap";
import { confirm } from "react-confirm-box";
import { getAuthToken } from "../../helpers/authentication";
import { toastNotification } from "../../helpers/toastHelpers";
import { ListBoxComponent } from "@syncfusion/ej2-react-dropdowns";

export class ContactReasons extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editData: [],
      orderData: [],
      loading: true,
      editModal: false,
      orderModal: false,
      showSuccess: false,
      showError: false,
      showDelete: false,
      showDisplayOrder: false,
      Id: 0,
      Title: "",
      Destination: "",
      CCAddress: "",
      DisplayOrder: 0,
      AddedBy: 0,
      DateAdded: new Date(),
      ModifiedBy: 0,
      DateModified: new Date(),
      Status: 0,
    };
    this.toggle = this.toggle.bind(this);
    this.toggleOrderModal = this.toggleOrderModal.bind(this);
    this.onItemDrop = this.onItemDrop.bind(this);
    this.SaveOrder = this.SaveOrder.bind(this);
  }

  toggle() {
    this.setState({
      editModal: !this.state.editModal,
    });
  }

  toggleOrderModal() {
    this.setState({
      orderModal: !this.state.orderModal,
    });
  }

  componentDidMount() {
    document.title = "Contact Reasons";
    this.loadData();
  }

  returnDisplayOrder() {
    return (
      <FormGroup className="mb-3">
        <Label for="tbDisplayOrder">Display Order</Label>
        <Input
          id="tbDisplayOrder"
          name="tbDisplayOrder"
          className="form-control text-end"
          placeholder="Display Order"
          type="number"
          required
          value={this.state.DisplayOrder}
          onChange={(e) => this.setState({ DisplayOrder: e.target.value })}
        />
      </FormGroup>
    );
  }

  editItem = (event) => {
    event.stopPropagation();
    const id = event.currentTarget.getAttribute("data-recordid");

    if (id > 0) {
      const data = this.state.editData.find((item) => {
        return item.Id === parseInt(id);
      });
      this.setState({
        Id: data.Id,
        Title: data.Title,
        Destination: data.Destination,
        CCAddress: data.CCAddress,
        DisplayOrder: data.DisplayOrder,
        AddedBy: data.AddedBy,
        DateAdded: data.DateAdded,
        ModifiedBy: data.ModifiedBy,
        DateModified: data.DateModified,
        Status: data.Status,
      });
    } else {
      //clear state fields
      this.setState({
        Id: 0,
        Title: "",
        Destination: "",
        CCAddress: "",
        DisplayOrder: 0,
        AddedBy: 0,
        DateAdded: new Date(),
        ModifiedBy: 0,
        DateModified: new Date(),
        Status: 0,
      });
    }
    this.setState({
      editId: id,
      editModal: true,
      showError: false,
      showSuccess: false,
    });
  };

  saveItem = async (event) => {
    event.stopPropagation();
    const data = this.state.editData.find((item) => {
      return item.Id === parseInt(this.state.editId);
    });
    if (this.state.editId > 0) {
      //do not overwrite the following fie when updating
      this.setState({
        AddedBy: data.AddedBy,
        DateAdded: data.DateAdded,
        Id: data.Id,
        Status: data.Status,
      });
      // this.state.AddedBy = data.AddedBy;
      // this.state.DateAdded = data.DateAdded;
      // this.state.Id = data.Id;
      // this.state.Status = data.Status;
    } else {
      this.setState({
        Status: 1,
      });
      // this.state.Status = 1;
    }
    this.saveData(this.state.editId);
    this.setState({ editModal: false });
  };

  deleteItem = async (event) => {
    event.stopPropagation();
    this.setState({ showError: false, showSuccess: false });
    const id = event.currentTarget.getAttribute("data-recordid");
    const result = await confirm("Are you sure you want to delete this item?");
    if (result) {
      this.deleteData(id);
    }
  };

  static renderDataTable(data, editFunction, deleteFunction) {
    return (
      <table className="table table-striped" aria-labelledby="tabelLabel">
        <thead>
          <tr>
            <th>Display Order</th>
            <th>Title</th>
            <th className="text-end">Actions</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item) => (
            <tr key={item.Id}>
              <td>{item.DisplayOrder}</td>
              <td>{item.Title}</td>
              <td className="text-end">
                <div className="btn-group">
                  <Button
                    color="dark"
                    outline
                    size="sm"
                    data-toggle="tooltip"
                    data-placement="auto"
                    data-recordid={item.Id}
                    title="Edit"
                    onClick={editFunction}
                  >
                    <i className="far fa-edit"></i>
                  </Button>
                  <Button
                    color="dark"
                    outline
                    size="sm"
                    data-toggle="tooltip"
                    data-placement="auto"
                    data-recordid={item.Id}
                    title="Delete"
                    onClick={deleteFunction}
                  >
                    <i className="far fa-trash-alt text-danger"></i>
                  </Button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }

  onItemDrop(e) {
    const orderData = [];
    e.source.currentData.map((item, idx) =>
      orderData.push({ DisplayOrder: (idx + 1), Id: item.Id, Title: item.Title })
    );
    this.setState({ orderData })
  }

  render() {
    let contents = this.state.loading ? (
      <p className="text-center">
        <i className="fas fa-spinner fa-spin me-2"></i>Loading...
      </p>
    ) : (
      ContactReasons.renderDataTable(
        this.state.editData,
        this.editItem,
        this.deleteItem
      )
    );

    return (
      <>
        <div className="container">
          <Row>
            <Col xs={8}>
              <h1>Contact Reasons Administration</h1>
            </Col>
            <Col xs={4} className="text-end align-self-center">
              <Button color='secondary' outline size='sm' onClick={this.toggleOrderModal} className='me-2'>Set Display Order <i className='fas fa-sort ms-2'></i></Button>

              <Button
                color="primary"
                size="sm"
                data-recordid="0"
                onClick={this.editItem}
              >
                Add New <i className="fas fa-plus-circle ms-2"></i>
              </Button>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>{contents}</Col>
          </Row>
        </div>
        <Modal
          isOpen={this.state.editModal}
          toggle={this.toggle}
          className={this.props.className}
          scrollable
          size="lg"
          backdrop="static"
        >
          <ModalHeader
            toggle={this.toggle}
            close={
              <button className="close" onClick={this.toggle}>
                <i className="fas fa-times"></i>
              </button>
            }
          >
            Edit Contact Reasons
          </ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup className="mb-3">
                <Label for="tbTitle">Title</Label>
                <Input
                  id="tbTitle"
                  name="tbTitle"
                  className="form-control"
                  placeholder="Title"
                  type="text"
                  maxLength="350"
                  required
                  value={this.state.Title}
                  onChange={(e) => this.setState({ Title: e.target.value })}
                />
              </FormGroup>

              <FormGroup className="mb-3">
                <Label for="tbDestination">Destination</Label>
                <Input
                  id="tbDestination"
                  name="tbDestination"
                  className="form-control"
                  placeholder="Destination"
                  type="text"
                  maxLength="500"
                  required
                  value={this.state.Destination}
                  onChange={(e) =>
                    this.setState({ Destination: e.target.value })
                  }
                />
              </FormGroup>

              <FormGroup className="mb-3">
                <Label for="tbCCAddress">CC Address</Label>
                <Input
                  id="tbCCAddress"
                  name="tbCCAddress"
                  className="form-control"
                  placeholder="CC Address"
                  type="text"
                  maxLength="500"
                  value={this.state.CCAddress}
                  onChange={(e) => this.setState({ CCAddress: e.target.value })}
                />
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="dark" size="sm" onClick={this.toggle}>
              Cancel <i className="far fa-times-circle ms-2"></i>
            </Button>
            <Button color="success" size="sm" onClick={this.saveItem}>
              Save <i className="far fa-check-circle ms-2"></i>
            </Button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={this.state.orderModal} toggle={this.toggleOrderModal} scrollable size='lg' backdrop="static">
          <ModalHeader toggle={this.toggleOrderModal} close={<button className='btn-close' onClick={this.toggleOrderModal}></button>}>Set Display Order</ModalHeader>

          <ModalBody>
            <ListBoxComponent dataSource={this.state.orderData} allowDragAndDrop={true} fields={{ text: "Title" }} drop={this.onItemDrop} />
          </ModalBody>
          <ModalFooter>
            <Button color='dark' size='sm' onClick={this.toggleOrderModal}>Cancel <i className="far fa-times-circle ms-2"></i></Button>
            <Button color='success' size='sm' onClick={this.SaveOrder}>Save <i className="far fa-check-circle ms-2"></i></Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }


  async SaveOrder() {
    var bearer = 'Bearer ' + getAuthToken();
    const data = JSON.stringify(this.state.orderData);
    this.setState({ orderModal: false, loading: true })
    try {
      const response = await fetch("api/contactreasons/SaveDisplayOrder", {
        method: "POST",
        withCredentials: true,
        credentials: "include",
        headers: {
          "Authorization": bearer,
          "Content-Type": "application/json"
        },
        body: data
      });

      if (response.ok) {
        toastNotification("Display Order", 'Display order was successfully saved!', true);
        this.loadData();
      }

    } catch (e) {
      console.error(e);
    }
  }


  async loadData() {
    var bearer = "Bearer " + getAuthToken();
    try {
      const response = await fetch("api/contactreasons", {
        method: "GET",
        withCredentials: true,
        credentials: "include",
        headers: {
          Authorization: bearer,
          "Content-Type": "application/json",
        },
      });
      if (response.ok) {
        const data = await response.json();
        let orderData = [];
        data.sort(function (a, b) { return a.DisplayOrder - b.DisplayOrder }).map((item, idx) => orderData.push({ Id: item.Id, DisplayOrder: item.DisplayOrder, Title: item.Title }));
        this.setState({ editData: data, orderData, loading: false });
      } else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401) this.props.history.push("/login");
      }
    } catch (e) {
      console.error(e);
    }
  }

  async saveData(dataId) {
    this.setState({ loading: true, showError: false, showSuccess: false });

    var bearer = "Bearer " + getAuthToken();
    var data = {
      Id: this.state.Id,
      Title: this.state.Title,
      Destination: this.state.Destination,
      CCAddress: this.state.CCAddress,
    };

    try {
      const response = await fetch("api/contactreasons", {
        method: "POST",
        withCredentials: true,
        credentials: "include",
        headers: {
          Authorization: bearer,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      if (response.ok) {
        await response.json();
        this.loadData();
        this.setState({ showSuccess: true });
        toastNotification(
          "Contact Reasons",
          "The contact reason was successfully saved!",
          true
        );
      } else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401) this.props.history.push("/login");
      }
    } catch (e) {
      console.error(e);
      this.setState({ loading: false, showError: true });
      toastNotification(
        "Contact Reasons",
        "There was an error saving the contact reason!",
        false
      );
    }
  }

  async deleteData(dataId) {
    this.setState({ loading: true });

    var bearer = "Bearer " + getAuthToken();
    try {
      const response = await fetch("api/contactreasons/" + dataId, {
        method: "DELETE",
        withCredentials: true,
        credentials: "include",
        headers: {
          Authorization: bearer,
          "Content-Type": "application/json",
        },
      });
      if (response.ok) {
        await response.json();
        this.loadData();
        this.setState({ showDelete: true });
        toastNotification(
          "Contact Reasons",
          "The contact reason was successfully deleted!",
          true
        );
      } else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401) this.props.history.push("/login");
      }
    } catch (e) {
      console.error(e);
      this.setState({ loading: false, showError: true });
      toastNotification(
        "Contact Reasons",
        "There was an error when deleting the contact reason!",
        false
      );
    }
  }
}
