/* eslint-disable default-case */
import React, { Component } from 'react';
import { Button, Modal, ModalFooter, ModalHeader, ModalBody, Row, Col } from 'reactstrap';
import { ColumnDirective, ColumnsDirective, CommandColumn, GridComponent, Sort, Edit, Toolbar } from '@syncfusion/ej2-react-grids';
import { ListBoxComponent } from '@syncfusion/ej2-react-dropdowns';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { Inject } from '@syncfusion/ej2-react-richtexteditor';
import { confirm } from "react-confirm-box";
import { getAuthToken, getLoginToken } from '../../helpers/authentication';
import { fieldRequired } from '../../helpers/validation';
import ImageEditor from './ImageEditor';
import { toastNotification } from '../../helpers/toastHelpers';

export class Innovations extends Component {

  constructor(props) {
    super(props);
    const commandTemplate = [
      { type: 'Edit', buttonOption: { cssClass: 'e-flat', iconCss: 'e-edit e-icons' } },
      { type: 'Delete', buttonOption: { cssClass: 'e-flat', iconCss: 'e-delete e-icons' } }
    ];

    this.state = {
      editData: [], orderData: [], loading: true, editModal: false, orderModal: false, gridCommands: commandTemplate, gridToolbar: ['Search'],
      Id: 0,
      Title: '',
      ImageName: '',
      StoredFile: '',
      FileData: '',
      ExistingFile: ''
    };

    this.orderRef = null;
    this.toggle = this.toggle.bind(this);
    this.toggleOrderModal = this.toggleOrderModal.bind(this);
    this.onItemDrop = this.onItemDrop.bind(this);
    this.SaveOrder = this.SaveOrder.bind(this);

    this.handleFileChange = this.handleFileChange.bind(this);

  }

  toggle() {
    this.setState({
      editModal: !this.state.editModal
    });
  }

  toggleOrderModal() {
    this.setState({
      orderModal: !this.state.orderModal
    });
  }


  componentDidMount() {
    document.title = "Innovations Administration";
    this.checkLoginStatus();
    this.loadData();
  }

  async checkLoginStatus() {
    const loginToken = getLoginToken();
    const bearer = "Bearer " + getAuthToken();

    try {
      const response = await fetch("/api/userlogin/CheckLoginStatus/" + loginToken, {
        method: "GET",
        headers: {
          Authorization: bearer,
          "Content-Type": "application/json"
        }
      });

      if (!response.ok)
        window.location.href = "/login";
    } catch (e) {
      console.error(e);
    }
  }

  editItem = (id) => {
    if (id > 0) {
      const data = this.state.editData.find((item) => { return item.Id === id });
      const ExistingFile = data.ImageName.split('\\')[2];
      this.setState({ Id: data.Id, Title: data.Title, ImageName: data.ImageName, ExistingFile, FileData: "" });
    }
    else {
      //clear state fields
      this.setState({
        Id: 0,
        Title: '',
        ImageName: '',
        ExistingFile: '',
        FileData: ''
      });
    }
    this.setState({ editModal: true });
  };

  saveItem = async (event) => {
    event.stopPropagation();
    var valid = true;

    valid &= fieldRequired(this.state.Title, "errTitle", "* required");
    if (valid) {
      // if (this.state.editId > 0) { //do not overwrite the following fie when updating
      //   this.state.Id = data.Id;
      // }
      // else {
      //   this.state.Status = 1;
      // }
      this.saveData(this.state.Id);
      this.setState({ editModal: false });
    }
  }

  deleteItem = async (id) => {
    const confirmStyles = {
      classNames: {
        confirmButton: 'btn btn-sm confirm-button',
        cancelButton: 'btn btn-sm cancel-button',
      }
    }
    const result = await confirm("Are you sure you want to delete this item?", confirmStyles);
    if (result) {
      this.deleteData(id);
    }
  }

  onGridCommand = (args) => {
    switch (args.commandColumn.type) {
      case 'Edit':
        this.editItem(args.rowData.Id);
        break;
      case 'Delete':
        this.deleteItem(args.rowData.Id);
        break;
    }
  }

  static renderDataTable(data, gridCommands, gridToolbar, commandClick) {
    return (
      <GridComponent dataSource={data} ref={g => this.grid = g} commandClick={commandClick} allowSorting={true} toolbar={gridToolbar} >
        <ColumnsDirective>
          <ColumnDirective field='DisplayOrder' width={"50"} headerText='Display Order' />
          <ColumnDirective field='Title' width='100' headerText="Title" />
          <ColumnDirective headerText='Actions' width='100' commands={gridCommands} />
        </ColumnsDirective>
        <Inject services={[Sort, Edit, CommandColumn, Toolbar]} />
      </GridComponent>
    );
  }

  handleFileChange(ImageName, FileData) {
    this.setState({
      ImageName, FileData
    });
  }

  onItemDrop(e) {
    const orderData = [];
    e.source.currentData.map((item, idx) =>
      orderData.push({ DisplayOrder: (idx + 1), Id: item.Id, Title: item.Title })
    );
    this.setState({ orderData })
  }

  render() {
    let contents = this.state.loading ? <p className='text-center'><i className='fas fa-spinner fa-spin me-2'></i>Loading...</p> : Innovations.renderDataTable(this.state.editData, this.state.gridCommands, this.state.gridToolbar, this.onGridCommand);

    return (
      <>
        <div className="container">
          <Row>
            <Col xs={6}>
              <h1>Innovations Administration</h1>
            </Col>
            <Col xs={6} className="text-end align-self-center">
              <Button color='secondary' outline size='sm' onClick={this.toggleOrderModal} className='me-2'>Set Display Order <i className='fas fa-sort ms-2'></i></Button>
              <Button color="primary" size="sm" data-recordid="0" onClick={this.editItem}>Add New <i className="fas fa-plus-circle ms-2"></i></Button>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              {contents}
            </Col>
          </Row>
        </div>

      

        <Modal isOpen={this.state.editModal} toggle={this.toggle} className={this.props.className} scrollable size="lg" backdrop="static">
          <ModalHeader toggle={this.toggle} close={<button className="close" onClick={this.toggle}><i className="fas fa-times"></i></button>}>Edit Innovations</ModalHeader>
          <ModalBody>
            <div className='mb-3'>
              <TextBoxComponent id='tbTitle' name='tbTitle' placeholder='Title' type='text' maxLength='250' floatLabelType='Auto' showClearButton={true} value={this.state.Title} onChange={e => this.setState({ Title: e.target.value })} /> <div id='tbTitleError' className='Error-Text' />
              <div id="errTitle" className='Error-Text' />
            </div>

            <div className='mb-3'>
              {/* <TextBoxComponent id='tbImageName' name='tbImageName' placeholder='Image Name' type='text' maxLength='250' floatLabelType='Auto' showClearButton={true} value={this.state.ImageName} onChange={e => this.setState({ ImageName: e.target.value })} /> <div id='tbImageNameError' className='Error-Text' />
              <label>Image</label>
              <input type='file' className='form-control form-control-sm' onChange={this.handleFileChange} />
              <div dangerouslySetInnerHTML={{ __html: `<span class='text-muted fw-bolder'>Existing:</span> ${this.state.ExistingFile}` }} hidden={this.state.ExistingFile === ""}></div>
        */}
              <ImageEditor LabelText={"Image"} ExistingImage={this.state.ImageName} FullWidth={true} OnChangeMethod={this.handleFileChange} />
            </div>


          </ModalBody>
          <ModalFooter>
            <Button color="dark" size="sm" onClick={this.toggle}>Cancel <i className="far fa-times-circle ms-2"></i></Button>
            <Button color="success" size="sm" onClick={this.saveItem}>Save <i className="far fa-check-circle ms-2"></i></Button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={this.state.orderModal} toggle={this.toggleOrderModal} scrollable size='lg' backdrop="static">
          <ModalHeader toggle={this.toggleOrderModal} close={<button className='btn-close' onClick={this.toggleOrderModal}></button>}>Set Display Order</ModalHeader>

          <ModalBody>
            <ListBoxComponent dataSource={this.state.orderData} allowDragAndDrop={true} fields={{ text: "Title" }} drop={this.onItemDrop} />
          </ModalBody>
          <ModalFooter>
            <Button color='dark' size='sm' onClick={this.toggleOrderModal}>Cancel <i className="far fa-times-circle ms-2"></i></Button>
            <Button color='success' size='sm' onClick={this.SaveOrder}>Save <i className="far fa-check-circle ms-2"></i></Button>
          </ModalFooter>
        </Modal>
      </>

    );
  }

  async SaveOrder() {
    var bearer = 'Bearer ' + getAuthToken();
    const data = JSON.stringify(this.state.orderData);
    this.setState({ orderModal: false, loading: true })
    try {
      const response = await fetch("api/innovations/SaveDisplayOrder", {
        method: "POST",
        withCredentials: true,
        credentials: "include",
        headers: {
          "Authorization": bearer,
          "Content-Type": "application/json"
        },
        body: data
      });

      if (response.ok) {
        toastNotification("Display Order", 'Display order was successfully saved!', true);
        this.loadData();
      }

    } catch (e) {
      console.error(e);
    }
  }

  async loadData() {

    var bearer = 'Bearer ' + getAuthToken();
    try {
      const response = await fetch('api/innovations', {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        let orderData = [];
        data.sort(function (a, b) { return a.DisplayOrder - b.DisplayOrder }).map((item, idx) => orderData.push({ Id: item.Id, DisplayOrder: item.DisplayOrder, Title: item.Title }));
        this.setState({ editData: data, orderData, loading: false });
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          this.props.history.push("/login");
      }

    } catch (e) {
      console.error(e);
    }
  }

  async saveData() {

    this.setState({ loading: true, showError: false, showSuccess: false });

    var bearer = 'Bearer ' + getAuthToken();
    var data = { Id: this.state.Id, Title: this.state.Title, ImageName: this.state.ImageName, FileData: this.state.FileData };
    try {
      const response = await fetch('api/innovations', {
        method: 'POST',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data),
      });
      if (response.ok) {
        await response.json();
        this.loadData();
        toastNotification("Innovations", 'The innovations was successfully saved!', true);
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          this.props.history.push("/login");
        else
          toastNotification("Innovations", 'There was an error saving the innovations!', false);
      }

    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
      toastNotification("Innovations", 'There was an error saving the innovations!', false);
    }
  }

  async deleteData(dataId) {
    this.setState({ loading: true });

    var bearer = 'Bearer ' + getAuthToken();
    try {
      const response = await fetch('api/innovations/' + dataId, {
        method: 'DELETE',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        await response.json();
        this.loadData();
        toastNotification("Innovations", 'The innovations was successfully deleted!', true);
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          this.props.history.push("/login");
      }
    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
      toastNotification("Innovations", 'There was an error deleting the innovations!', false);
    }
  }
}

