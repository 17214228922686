import HomeBanner from './home-modules/home-banner';
import HomeIndustries from './home-modules/home-industries';
import HomeMedia from './home-modules/home-media';
import HomeAbout from './home-modules/home-about';
import HomeLinkBlocks from './home-modules/home-link-blocks';
import { useEffect, useState } from 'react';

const Home = () => {

  const [Contents, setContents] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    loadData();
  }, [loading])

  const loadData = async () => {
    try {
      const response = await fetch("api/pagecontents/GetContentByPage/home", {
        method: "GET",
        withCredentials: true,
        credentials: "include",
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (response.ok) {
        const ContentList = await response.json();

        setContents(ContentList);
        setLoading(false);
      } else {
        console.log(response.status + ": " + response.statusText);
        setLoading(false);
      }
    } catch (e) {
      console.error("Page Content:", e);
    }
  }

  const content = loading ? <em>Loading ...</em> : <div>
    <HomeBanner />
    <HomeLinkBlocks Contents={Contents} />
    <HomeIndustries />
    <HomeMedia />
    <HomeAbout Contents={Contents} />
  </div>
  return (
    content
  );
}

export default Home;
