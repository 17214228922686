import React, { Component } from "react";
import { Container } from "reactstrap";
import { NavMenu } from "./NavMenu";
import { AdminNavMenu } from "./admin-nav";
import { Footer } from "./footer";
import { AdminFooter } from "./admin-footer";
import { getAuthToken, getLoginToken } from "../../helpers/authentication";
//import { useLocation } from 'react-router-dom'

export class Layout extends Component {
  static displayName = Layout.name;

  constructor(props) {
    super(props);

    this.state = {
      loading: true
    }

    // this.checkLoginStatus = this.checkLoginStatus.bind(this);
    this.renderAdminLayout = this.renderAdminLayout.bind(this);
  }

  currentLocation = () => {
    var location = window.location.pathname;
    return location;
  }

  generalStyle = {
    padding: 0
  }

  renderAdminLayout(children) {
    // this.checkLoginStatus();
    return (
      <>
        <AdminNavMenu />
        <Container>{children}</Container>
        <AdminFooter />
      </>
    )
  }

  renderSiteLayout(children) {
    return (
      <>
        <NavMenu />
        <div className="align-container">
          <Container fluid style={this.generalStyle}>{children}</Container>
        </div>
        <Footer />
      </>
    )
  }



  render() {
    const content = this.currentLocation().indexOf("/admin") >= 0 ? this.renderAdminLayout(this.props.children) : this.renderSiteLayout(this.props.children);
    return (
      content
    );
  }
} 
