import ReCAPTCHA from "react-google-recaptcha";
import { useState, useEffect } from "react";
import { loadData } from "./GetData";
import { toastNotification } from "../helpers/toastHelpers";
import { fieldRequired } from "../helpers/validation";

const ContactUs = () => {
  const [contactReasons, setContactReasons] = useState([]);
  const [firstName, setFirstName] = useState("");
  const [surname, setSurname] = useState("");
  // const [companyName, setCompanyName] = useState("");
  // const [country, setCountry] = useState("");
  const [reasonID, setReasonID] = useState("");
  const [message, setMessage] = useState("");
  const [contact, setContact] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [captchaValue, setCaptchaValue] = useState("");

  useEffect(() => {
    const getReasons = async () => {
      const reasonsFromServer = await loadData("contactreasons/GetContactReasons");
      reasonsFromServer.sort((a, b) => a.DisplayOrder - b.DisplayOrder);
      setContactReasons(reasonsFromServer);
    };

    getReasons();
  }, []);

  const onCaptchaChange = (value) => {
    setCaptchaValue(value);
    fieldRequired(value, "errCaptcha", "* Required");
  };

  const getReasonID = (selectedReasonId) => {
    // const contactReason = contactReasons.find((elem) => elem.Title === reason);
    setReasonID(selectedReasonId);
    fieldRequired(selectedReasonId, "errReason", "* Required");
  };

  const clearForm = () => {
    setFirstName("");
    setSurname("");
    setEmailAddress("");
    setContact("");
    setCaptchaValue("");
    setMessage("");
  }

  const onSubmit = async (e) => {
    // let bearer = `Bearer ${getAuthToken()}`;

    let isValid = true;
    isValid &= fieldRequired(firstName, "errFirstName", "* Required")
    isValid &= fieldRequired(surname, "errSurname", "* Required")
    isValid &= fieldRequired(reasonID, "errReason", "* Required");
    isValid &= fieldRequired(contact, "errContact", "* Required")
    isValid &= fieldRequired(emailAddress, "errEmail", "* Required")
    isValid &= fieldRequired(captchaValue, "errCaptcha", "* Required");

    if (isValid) {
      e.stopPropagation();
      e.target.disabled = true;
      e.target.innerText = "Submitting...";
      const contactUsObj = {
        Id: 0,
        UserId: 0,
        ReasonId: reasonID,
        Firstname: firstName,
        Surname: surname,
        Email: emailAddress,
        Mobile: contact,
        IPAddress: "",
        DateSent: new Date(),
        DateAttended: new Date(),
        AttendedBy: 0,
        Status: 1,
        Message: message,
      };


      try {
        const response = await fetch(`api/contactmessages/SubmitMessage`, {
          method: "POST",
          // withCredentials: true,
          // credentials: "include",
          headers: {
            Authorization: "",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(contactUsObj),
        });
        if (response.ok) {
          clearForm();

          toastNotification(
            "Contact Form",
            "Your message was sent successfully!",
            true
          );

          // recaptcha.reset();
          window.grecaptcha.reset();
          e.target.disabled = false;
          e.target.innerText = "Submit";
        } else {
          console.log(`${response.status}: ${response.statusText}`);

          window.grecaptcha.reset();

          e.target.disabled = false;
          e.target.innerText = "Submit";
        }
      } catch (error) {
        console.error(error);
        toastNotification(
          "Contact Form",
          "An error occured when sending your message.",
          false
        );
        window.grecaptcha.reset();
        e.target.disabled = false;
        e.target.innerText = "Submit";
      }
    }
    //  else {
    //   toastNotification(
    //     "Contact Form",
    //     "Captcha has not been completed, please tick the checkbox and resend your message.",
    //     false
    //   );
    // }

  };

  return (
    <div className="contact-us mb-5 container">
      <div className="row">
        <div className="col-md-8">
          <div className="pe-md-5">
            <h1>Contact Us</h1>
            <div className="pb-3 green-text">
              <p>
                Please fill out a quick form and we will be in touch as soon as
                we can.
              </p>
            </div>
            <div className="row">
              <div className="col-md-6 mb-3">
                <input
                  type="text"
                  className="form-control"
                  id="tbName"
                  placeholder="First Name*"
                  required
                  value={firstName}
                  onChange={(e) => { setFirstName(e.target.value); fieldRequired(e.target.value, "errFirstName", "* Required"); }}
                ></input>
                <div id="errFirstName" className="ErrorText"></div>
              </div>
              <div className="col-md-6 mb-3">
                <input
                  type="text"
                  className="form-control"
                  id="tbName"
                  placeholder="Surname*"
                  required
                  value={surname}
                  onChange={(e) => { setSurname(e.target.value); fieldRequired(e.target.value, "errSurname", "* Required"); }}
                ></input>
                <div id="errSurname" className="ErrorText"></div>

              </div></div>
            {/* <div className="mb-3">
              <input
                type="text"
                className="form-control"
                id="tbCompanyName"
                placeholder="Company Name"
                onChange={(e) => setCompanyName(e.target.value)}
              ></input>
            </div> */}
            {/* <div className="mb-3">
              <input
                type="text"
                className="form-control"
                id="tbCountry"
                placeholder="Country"
                onChange={(e) => setCountry(e.target.value)}
              ></input>
            </div> */}

            <div className="mb-3">
              <label htmlFor="subjects">Please choose a subject:
                <span id="errReason" className="ErrorText ms-2"></span>
              </label>
              <select
                className="contact-select"
                name="subjects"
                id="subjectSelect"
                onChange={(e) => getReasonID(e.target.value)}
              >
                <option value={0}>- Select Subject -</option>
                {contactReasons.map((reason) => (
                  <option value={reason.Id} key={reason.Id} >{reason.Title}
                  </option>
                ))}
              </select>
            </div>
            <div className="row">
              <div className="mb-3 col-md-6">
                <input
                  type="text"
                  className="form-control"
                  id="tbContact"
                  placeholder="Contact*"
                  required
                  value={contact}
                  onChange={(e) => { setContact(e.target.value); fieldRequired(e.target.value, "errContact", "* Required"); }}
                ></input>
                <div id="errContact" className="ErrorText"></div>
              </div>
              <div className="mb-3 col-md-6">
                <input
                  type="email"
                  className="form-control"
                  id="tbEmailAddress"
                  placeholder="Email Address*"
                  required
                  value={emailAddress}
                  onChange={(e) => { setEmailAddress(e.target.value); fieldRequired(e.target.value, "errEmail", "* Required"); }}
                ></input>
                <div id="errEmail" className="ErrorText"></div>

              </div>
            </div>
            <div className="mb-3">
              <textarea
                type="text"
                className="form-control"
                id="tbYourMessageHere"
                placeholder="Your Message Here"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                rows={4}
              ></textarea>
            </div>
            <div className="mb-3">
              <ReCAPTCHA sitekey="6LeNVvcpAAAAANZE4YAjB7jJu_Rtg3f2pPGmPECN" onChange={onCaptchaChange} />
              <div id="errCaptcha" className="ErrorText"></div>
            </div>
            <div>
              <button type="submit" className="btn-submit" onClick={onSubmit}>
                SUBMIT
              </button>
            </div>
          </div>
        </div>

        <div className="col-md-4 green-background mt-5 mb-5">
          <div className="p-4">
            <h6>For support or any enquiries:</h6>
            <p>Email us at: <a href="mailto:enquiries@harnesspro.co.za" style={{ color: "#fff" }}>enquiries@harnesspro.co.za</a></p>
            <div>
              <p>
                <b>Main Manufacturing:</b>
                <br />Units 2-5 Ngoya Park,
                <br />
                109 Alumina Allee,
                <br />
                Richards Bay, 3900
              </p>
              <p>
                <b>Main Manufacturing Tel:</b> <br />
                +27(0)35 751 1102
              </p>
            </div>
            <p>
              <b>Aftermarket Support:</b>
              <br />7 Model Rd,
              <br />
              Jet Park,
              <br />
              Boksburg, 1495
            </p>

            <p>
              <b>Aftermarket Support Tel:</b>
              <br />
              +27(0)11 823 5650
            </p>
          </div>
        </div>
      </div>
      <div className="text-end">
        <a href="https://www.facebook.com/HarnessPro/" target="_blank" rel="noreferrer">
          <img src="img\contact-us\facebook-icon.png" alt="facebook icon" className="px-5" />
        </a>
        <a href="https://www.linkedin.com/in/harnesspro-south-africa-b7a302140/?originalSubdomain=za" target="_blank" rel="noreferrer">
          <img src="img\contact-us\linkedIn-icon.png" alt="linkedIn icon" />
        </a>
      </div>
    </div>
  );
};

export default ContactUs;
