import React, { useState } from 'react'
import { getCurrentDomain } from '../../helpers/lookup';

export default function IndustryBlock({ Index, ActiveImage, InactiveImage, Title }) {

    const [isActive, setIsActive] = useState(false);
    return (
        <div className=''>
            <a href={`/industries/${Title.replaceAll(" ", "")}`} onMouseEnter={() => setIsActive(true)} onMouseLeave={() => setIsActive(false)} className="industryLink" key={Index}>
                <div className="row w-100 h-100 align-items-center">
                    <div className="col-5 img-block">
                        <div className="w-100">
                            <img
                                src={getCurrentDomain() + (isActive ? ActiveImage : InactiveImage)}
                                // src={getCurrentDomain() + ActiveImage}
                                alt={Title}
                                className="img-fluid w-100"
                            /></div>
                    </div>
                    <div className="col-7 px-0 detail-block align-self-center">
                        <h4>{Title}</h4>
                        {/* <BsArrowRight className="arrowIcon" /> */}
                        <i className="fas fa-arrow-right fa-2x"></i>
                    </div>
                </div>
            </a>
        </div>
    )
}
