/* eslint-disable default-case */
import { useEffect, useState } from "react";
import { getCurrentDomain } from "../helpers/lookup";

const AboutUs = () => {

  const [Contents, setContents] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    loadData();
  })

  const loadData = async () => {
    try {
      const response = await fetch("api/pagecontents/GetContentByPage/about", {
        method: "GET",
        withCredentials: true,
        credentials: "include",
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (response.ok) {
        const ContentList = await response.json();

        setContents(ContentList);
        setLoading(false);
      } else {
        console.log(response.status + ": " + response.statusText);
        setLoading(false);
      }
    } catch (e) {
      console.error("Page Content Error:", e);
    }
  }

  const RenderContent = (type) => {

    let content = null;
    switch (type.toLowerCase()) {
      case "holding":
        content = Contents.find(c => c.Slug === "about-us-holding-company");
        break;
      case "objectives":
        content = Contents.find(c => c.Slug === "about-us");
        break;
      case "mission":
        content = Contents.find(c => c.Slug === "about-us-mission-statement");
        break;
      case "image1":
        content = Contents.find(c => c.Slug === "about-us-image-1");
        break;
      case "image2":
        content = Contents.find(c => c.Slug === "about-us-image-2");
        break;
    }

    if (content)
      return content.Copy.replace("/DynamicData", getCurrentDomain() + "DynamicData");
    else
      return "";
  }

  const renderPage = () => {
    return (
      <div className="about-us mb-5 container">
        <div className="row">
          <div className="col-lg-8 px-md-5">
            <div className="p-3">
              <h1>Holding Company</h1>
            </div>
            <div className="para p-3" dangerouslySetInnerHTML={{ __html: RenderContent("holding") }}>
            </div>



            <div className="mt-4" >
              <h1>Our Products</h1>
            </div>

            <div className="para p-3" dangerouslySetInnerHTML={{ __html: RenderContent("objectives") }}>
            
            </div>
          </div>

          <div className="col-lg-4 background mt-5 ">
            <div className="p-3">
              <h3>Company Objectives</h3>
            </div>
            <div dangerouslySetInnerHTML={{ __html: RenderContent("mission") }}>
            </div>
          </div>
        </div>
        <div className="row text-center">
          <div className="col-md-6 my-5" dangerouslySetInnerHTML={{ __html: RenderContent("image1") }}>
          </div>
          <div className="col-md-6 my-5" dangerouslySetInnerHTML={{ __html: RenderContent("image2") }}>
          </div>
        </div>
      </div>
    );
  }

  const content = loading ? <em>Loading...</em> : renderPage();

  return (
    content
  );
}

export default AboutUs;
