import parse from 'html-react-parser';

export function formatDate(date) {
  return new Date(date).toDateString();
}

export function sortArticles(articles) {
  articles.sort((a, b) => {
    let date1 = new Date(a.ArticleDate);
    let date2 = new Date(b.ArticleDate);

    return date2 - date1;
  });

  return articles;
}

export function parseRichTextInArticle(article) {
  return Object.keys(article).length !== 0 ? parse(article.Copy) : '';
}
